import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { IBackgroundColor } from 'ui.shared/lib/types/StyledComponentsTypes';
import History from '../navigation/BrowserHistory';
import { useStoreActions } from '../store/Store';

const BottomMenu = styled.div<IBackgroundColor>`
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    margin-top: auto;
    font-size: 12px;
    background-color: ${(props) => props.backgroundColor};
`;

const Link = styled.div`
    display: flex;
    cursor: pointer;
    padding: 0 10px;
`;

/**
 * Interface for the properties of the bottom nav menu.
 */
interface IBottomNavMenu {
    /**
     * The background color of the nav container.
     */
    backgroundColor: string;
}

/**
 * The bottom menu for imprint and privacy policy.
 */
export const BottomNavMenu: FunctionComponent<IBottomNavMenu> = (props) => {
    /**
     * Store action to update the currently selected navigation key.
     */
    const updateSelectedNavKey = useStoreActions((actions) => actions.NavigationModel.updateSelectedNavKey);

    return (
        <BottomMenu backgroundColor={props.backgroundColor}>
            <Link
                onClick={() => {
                    updateSelectedNavKey('impressum');
                    History.push('/impressum');
                }}
            >
                Impressum
            </Link>
            <Link
                onClick={() => {
                    updateSelectedNavKey('datenschutz');
                    History.push('/datenschutz');
                }}
            >
                Datenschutzerklärung
            </Link>
        </BottomMenu>
    );
};
