import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import HarzerKurbelixeLogo from '../assets/HarzerKurbelixe.svg';
import KontrastMittelLogo from '../assets/LOGO_KM.png';

const ImprintViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 50px;
`;

const HarzerKurbelixeLogoContainer = styled.div`
    height: 200px;
    min-height: 200px;
    background-image: url(${HarzerKurbelixeLogo});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
`;

const SpacedDiv = styled.div`
    display: flex;
    flex-direction: column;
    min-height: max-content;
    padding-top: 20px;
`;

const MinHeightMaxContent = styled.div`
    display: flex;
    min-height: max-content;
`;

/**
 * View component for the imprint view.
 */
export const ImprintView: FunctionComponent = () => {
    return (
        <ImprintViewContainer>
            <h1>Kontakt und Impressum</h1>
            <HarzerKurbelixeLogoContainer />
            <h3>Verantwortlich für den Inhalt und die Umsetzung von harzerkurbelixe.de ist:</h3>
            <div>Isabel Liebecke, Heiko Thöne</div>
            <div>Buchbergstr. 57</div>
            <div>38871 Ilsenburg/Harz,</div>
            <div>Deutschland</div>
            <div>
                E-Mail-Adresse: <a href="mailto: info@HarzerKurbelixe.de">info@HarzerKurbelixe.de</a>
            </div>
            <SpacedDiv>
                <MinHeightMaxContent>Das Logo "HarzerKurbelixe" wurde durch HarzerKurbelixe und Dennis Ehrecke von Kontrast.Mittel erstellt.</MinHeightMaxContent>
                <MinHeightMaxContent>
                    <a href="https://kontrast-mittel.de" target="_blank" rel="noopener noreferrer">
                        <img src={KontrastMittelLogo} alt="Fehler" />
                    </a>
                </MinHeightMaxContent>
            </SpacedDiv>
            <SpacedDiv>Das Webangebot harzerkurbelixe.de wird von Isabel Liebecke und Heiko Thöne selbst finanziert.</SpacedDiv>
        </ImprintViewContainer>
    );
};
