import React, { useEffect, FunctionComponent } from 'react';
import History from '../navigation/BrowserHistory';
import { useStoreState, useStoreActions } from '../store/Store';
import { IIconStyles, Nav, INavLink, INavStyles, INavLinkGroup } from '@fluentui/react';

/**
 * Interface for the properties of the navigation menu.
 */
interface INavMenu {
    /**
     * The width of the navigation menu.
     */
    navMenuWidth?: number;

    /**
     * Height of the nav menu.
     */
    navHeight?: number | string;
}

/**
 * The navigation menu component.
 */
export const NavMenu: FunctionComponent<INavMenu> = (props) => {
    /**
     * Store state of the currently selected navigation key.
     */
    const selectedNavKey = useStoreState((state) => state.NavigationModel.selectedNavKey);

    /**
     * Store action to update the currently selected navigation key.
     */
    const updateSelectedNavKey = useStoreActions((actions) => actions.NavigationModel.updateSelectedNavKey);

    /**
     * Updates the selected navigation key to match the current location.
     */
    useEffect(() => {
        updateSelectedNavKey(History.location.pathname === '/' ? 'home' : History.location.pathname.substring(1));
    }, [selectedNavKey, updateSelectedNavKey]);

    /**
     * Styles of the navigation component.
     */
    const navStyles: Partial<INavStyles> = {
        root: {
            boxSizing: 'border-box',
            height: props.navHeight && props.navHeight,
            width: props.navMenuWidth && props.navMenuWidth,
        },
        groupContent: {
            marginBottom: 0,
        },
        linkText: {
            fontSize: 17,
            marginLeft: 10,
        },
        link: {
            height: 70,
        },
    };

    /**
     * Styles of the nav bar icons.
     */
    const navIconStyles: IIconStyles = {
        root: {
            fontSize: 20,
            marginLeft: 15,
        },
    };

    /**
     * Link groups of the navigation component.
     */
    const navLinkGroups: INavLinkGroup[] = [
        {
            links: [
                {
                    name: 'Start',
                    url: '',
                    iconProps: { iconName: 'Home', styles: navIconStyles },
                    key: 'home',
                    target: '/',
                },
                {
                    name: 'Über Uns',
                    url: '',
                    iconProps: { iconName: 'Group', styles: navIconStyles },
                    key: 'aboutus',
                    target: '/aboutus',
                },
                {
                    name: 'Harzliebe',
                    url: '',
                    iconProps: { iconName: 'Heart', styles: navIconStyles },
                    key: 'harzliebe',
                    target: '/harzliebe',
                },
                {
                    name: 'Kurbelixe on Tour',
                    url: '',
                    iconProps: { iconName: 'World', styles: navIconStyles },
                    key: 'ontour',
                    target: '/ontour',
                },
                {
                    name: 'Kurbelixe am Start',
                    url: '',
                    iconProps: { iconName: 'Medal', styles: navIconStyles },
                    key: 'amstart',
                    target: '/amstart',
                },
                {
                    name: 'Jeder km zählt',
                    url: '',
                    iconProps: { iconName: 'Cycling', styles: navIconStyles },
                    key: 'jederkmzaehlt',
                    target: '/jederkmzaehlt',
                },
                {
                    name: 'Lesestoff',
                    url: '',
                    iconProps: { iconName: 'ReadingMode', styles: navIconStyles },
                    key: 'lesestoff',
                    target: '/lesestoff',
                },
                // {
                //     name: 'Outtakes',
                //     url: '',
                //     iconProps: { iconName: 'MyMoviesTV', styles: navIconStyles },
                //     key: 'outtakes',
                //     target: '/outtakes',
                // },
                // {
                //     name: 'Gästebuch',
                //     url: '',
                //     iconProps: { iconName: 'ReadingMode', styles: navIconStyles },
                //     key: 'gaestebuch',
                //     target: '/gaestebuch',
                // },
            ],
        },
    ];

    /**
     * Click handler for the nav menu links.
     */
    const onLinkClick = (event?: React.MouseEvent<HTMLElement>, link?: INavLink) => {
        if (link === null || link === undefined || link.key === null || link.key === undefined || link.target === null || link.target === undefined) {
            return;
        }
        updateSelectedNavKey(link.key);
        History.push(link.target);
    };

    return <Nav onLinkClick={onLinkClick} selectedKey={selectedNavKey} styles={navStyles} groups={navLinkGroups} />;
};
