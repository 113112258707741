import React from "react";
import ReactDOM from "react-dom";
import { StoreProvider } from "easy-peasy";
import { Store } from "./store/Store";
import { Routes } from "./navigation/Routes";
import { loadTheme, initializeIcons } from "@fluentui/react";
import { LightTheme } from "ui.shared";
import ReactGA from "react-ga";

// Initialize the fluent ui icons.
initializeIcons();

// Initialize the fluent ui theme.
loadTheme({
  palette: LightTheme,
  defaultFontStyle: {
    fontFamily:
      "Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif",
    fontWeight: "regular",
  },
});

// Get the root element.
const rootElement = document.getElementById("root");

// Fetch the options from the frontend server.
export const _options = fetch("api/Options").then((response) =>
  response.json()
);

// App is started after options are retrieved.
_options.then((config) => {
  // Initialize google analytics.
  ReactGA.initialize(config.analyticsOptions.googleAnalyticsTrackingId, {
    gaOptions: {
      storage: "none",
    },
  });
  // Wrap the application with a store provider and render the application at the root element.
  ReactDOM.render(
    <StoreProvider store={Store}>
      <Routes />
    </StoreProvider>,
    rootElement
  );
});
