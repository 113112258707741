import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { DetailsList, SelectionMode, ConstrainMode, DetailsListLayoutMode, getTheme, IDetailsHeaderProps, ScrollablePane, ScrollbarVisibility, Sticky, StickyPositionType } from '@fluentui/react';
import { PaginationControls } from './PaginationControls';
import { IBackgroundColor, IBorderColor } from 'ui.shared/lib/types/StyledComponentsTypes';

const TableContainer = styled.div<IBackgroundColor>`
    display: flex;
    flex: 1;
    flex-direction: column;
    box-shadow: 0 1px 1px rgba(16, 27, 30, 0.15), 0 2px 2px rgba(16, 27, 30, 0.15), 0 4px 4px rgba(16, 27, 30, 0.15), 0 8px 8px rgba(16, 27, 30, 0.15),
        0 16px 16px rgba(16, 27, 30, 0.15);
    border-radius: 4px;
    background-color: ${(props) => props.backgroundColor};
`;

const TableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    position: relative;
`;

const PaginationControlsWrapper = styled.div<IBorderColor>`
    margin-top: auto;
    padding: 15px;
    border-top: 1px solid ${(props) => props.borderColor};
`;

/**
 * Interface for the properties of the table.
 */
interface ITable {
    /**
     * Whether the table is busy or not.
     */
    isBusy: boolean;

    /**
     * The columns of the table.
     */
    columns: any[];

    /**
     * The rows of the table.
     */
    data: any[];

    /**
     * The number of the current displayed page.
     */
    currentPage: number;

    /**
     * The maximum number of available pages.
     */
    availablePages: number;

    /**
     * Callback to navigate one page backward.
     */
    navigateBackward: () => void;

    /**
     * Callback to navigate one page forward.
     */
    navigateForward: () => void;

    /**
     * Callback to navigate to the first page.
     */
    navigateToStart: () => void;

    /**
     * Callback to navigate to the last page.
     */
    navigateToEnd: () => void;
}

/**
 * Basic table component.
 */
export const Table: FunctionComponent<ITable> = (props) => {
    const theme = getTheme();

    return (
        <TableContainer backgroundColor={theme.palette.white}>
            <TableWrapper>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <DetailsList
                        items={props.data}
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.unconstrained}
                        columns={props.columns}
                        selectionMode={SelectionMode.none}
                        onRenderDetailsHeader={(
                            headerProps: IDetailsHeaderProps | undefined,
                            defaultRender: ((props?: IDetailsHeaderProps | undefined) => JSX.Element | null) | undefined
                        ) => {
                            return (
                                <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
                                    {defaultRender && <div>{defaultRender(headerProps)}</div>}
                                </Sticky>
                            );
                        }}
                    />
                </ScrollablePane>
            </TableWrapper>
            {props.availablePages > 1 && (
                <PaginationControlsWrapper borderColor={theme.palette.neutralLight}>
                    <PaginationControls
                        currentPage={props.currentPage}
                        availablePages={props.availablePages}
                        navigateBackward={props.navigateBackward}
                        navigateForward={props.navigateForward}
                        navigateToEnd={props.navigateToEnd}
                        navigateToStart={props.navigateToStart}
                    />
                </PaginationControlsWrapper>
            )}
        </TableContainer>
    );
};
