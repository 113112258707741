import React, { FunctionComponent } from 'react';
import { Route } from 'react-router-dom';
import { ErrorMessage } from '../common/ErrorMessage';

/**
 * Interface for the properties of the route wrapper.
 */
interface IRouteWrapper {
    /**
     * The component that gets rendered in this route.
     */
    component: any;
    /**
     * The layout that is rendered around the component.
     */
    layout: any;
    /**
     * Whether this is an exact route or not.
     */
    exact: boolean;
    /**
     * The path of the route.
     */
    path: string;
}

/**
 * Component that creates a route that renders the given component wrapped by the given layout.
 */
export const RouteWrapper: FunctionComponent<IRouteWrapper> = ({ component: Component, layout: Layout, exact, path }) => {
    return (
        <Route
            exact={exact}
            path={path}
            render={(props) => (
                <Layout {...props}>
                    <ErrorMessage />
                    <Component />
                </Layout>
            )}
        />
    );
};
