import * as DOMPurify from 'dompurify';

/**
 * Secure the text to prevent xss attacks.
 * @param text The text to secure.
 */
export const returnSecureHtml = (text: string) => {
    const cleanHtml = DOMPurify.sanitize(text);
    return cleanHtml;
};
