import React, { FunctionComponent } from 'react';
import { IButtonStyles, IconButton, IIconStyles, TooltipHost } from '@fluentui/react';
import { openUrlInNewTab } from '../helper/UrlHelper';

/**
 * Properties for the url button component.
 */
interface IUrlButton {
    /**
     * The url to open in a new tab.
     */
    url: string;
}

/**
 * Button for opening urls with tooltip that displays the url.
 */
export const UrlButton: FunctionComponent<IUrlButton> = (props) => {
    /**
     * Styles of the world button.
     */
    const worldButtonStyles: IButtonStyles = {
        rootHovered: {
            backgroundColor: 'unset',
        },
        rootPressed: {
            backgroundColor: 'unset',
        },
    };

    /**
     * Styles of the world icon.
     */
    const worldIconStyles: IIconStyles = {
        root: {
            fontSize: 22,
        },
    };

    return (
        <TooltipHost content={props.url}>
            <IconButton styles={worldButtonStyles} iconProps={{ iconName: 'World', styles: worldIconStyles }} onClick={() => openUrlInNewTab(props.url)} />
        </TooltipHost>
    );
};
