import { getTheme } from '@fluentui/react';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { IBackgroundColor } from 'ui.shared';

const TagDisplayContainer = styled.div<IBackgroundColor>`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    min-width: max-content;
    width: 100%;
    height: 100%;
    padding: 4px;
    background-color: ${(props) => props.backgroundColor};
`;

/**
 * Interface of the visual component to display a tag.
 */
interface ITagDisplay {
    /**
     * Text to display inside of a visual tag.
     */
    text: string;
}

/**
 * Visual component to display a tag.
 */
export const TagDisplay: FunctionComponent<ITagDisplay> = (props) => {
    const theme = getTheme();

    return <TagDisplayContainer backgroundColor={theme.palette.neutralLighterAlt}>{props.text}</TagDisplayContainer>;
};
