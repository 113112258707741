import { getTheme } from '@fluentui/react';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { IBackgroundColor } from 'ui.shared';
import { BottomNavMenu } from '../menus/BottomNavMenu';

const FullscreenLayoutContainer = styled.div<IBackgroundColor>`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    background-color: ${(props) => props.backgroundColor};
`;

const ContentContainer = styled.div`
    display: flex;
    flex: 1;
    overflow-y: auto;
    overflow-x: auto;
`;

/**
 * Interface for the properties of the basic layout.
 */
interface IFullscreenLayout {
    /**
     * The children that will be rendered inside this layout.
     */
    children: any;
}

/**
 * The basic layout component.
 */
export const FullscreenLayout: FunctionComponent<IFullscreenLayout> = (props) => {
    const theme = getTheme();

    return (
        <FullscreenLayoutContainer backgroundColor={theme.palette.neutralLighter}>
            <ContentContainer>{props.children}</ContentContainer>
            <BottomNavMenu backgroundColor="none" />
        </FullscreenLayoutContainer>
    );
};
