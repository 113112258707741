/**
 * The fluent ui color palette for a dark theme.
 */
export const DarkTheme = {
    themePrimary: '#00add4',
    themeLighterAlt: '#000708',
    themeLighter: '#001c22',
    themeLight: '#00343f',
    themeTertiary: '#00687f',
    themeSecondary: '#0098ba',
    themeDarkAlt: '#16b4d8',
    themeDark: '#35bfde',
    themeDarker: '#66cfe7',
    neutralLighterAlt: '#201F1E',
    neutralLighter: '#31302f',
    neutralLight: '#2f2e2d',
    neutralQuaternaryAlt: '#2c2b2a',
    neutralQuaternary: '#2a2928',
    neutralTertiaryAlt: '#272727',
    neutralTertiary: '#c8c8c8',
    neutralSecondary: '#d0d0d0',
    neutralPrimaryAlt: '#dadada',
    neutralPrimary: '#ffffff',
    neutralDark: '#f4f4f4',
    black: '#f8f8f8',
    white: '#323130',
};
