import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { GlobalColors } from 'ui.shared';
import { IBackgroundColor, IFontColor } from 'ui.shared';
import { useStoreState, useStoreActions } from '../store/Store';
import { IButtonStyles, IconButton, IIconProps } from '@fluentui/react';

interface IErrorMessageOverlay {
    /**
     * The value of the display attribute.
     */
    display: string;
}

const ErrorMessageOverlay = styled.div<IErrorMessageOverlay>`
    display: ${(props) => props.display};
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 15px;
    left: 0;
    width: 100%;
    z-index: 1;
`;

const ErrorMessageContainer = styled.div<IBackgroundColor & IFontColor>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    padding: 10px;
    border-radius: 4px;
    color: ${(props) => props.fontColor};
    background-color: ${(props) => props.backgroundColor};
`;

const ErrorMessageWrapper = styled.div`
    display: flex;
    margin: auto;
`;

/**
 * Basic component to display an error message.
 */
export const ErrorMessage: FunctionComponent = () => {
    /**
     * Store state of the current occured error message.
     */
    const currentErrorMessage = useStoreState((state) => state.GlobalDataModel.currentErrorMessage);

    /**
     * Store action to update the current occured error message.
     */
    const updateCurrentErrorMessage = useStoreActions((actions) => actions.GlobalDataModel.updateCurrentErrorMessage);

    /**
     * Icon props of the close button.
     */
    const closeIcon: IIconProps = {
        iconName: 'ChromeClose',
    };

    /**
     * Styles of the close button.
     */
    const closeButtonStyles: IButtonStyles = {
        root: {
            color: GlobalColors.white,
        },
        rootHovered: {
            backgroundColor: 'unset',
        },
        rootPressed: {
            backgroundColor: 'unset',
        },
    };

    return (
        <ErrorMessageOverlay display={currentErrorMessage !== '' ? 'flex' : 'none'}>
            <ErrorMessageContainer fontColor={GlobalColors.white} backgroundColor={GlobalColors.errorRed}>
                <ErrorMessageWrapper>{currentErrorMessage}</ErrorMessageWrapper>
                <IconButton styles={closeButtonStyles} iconProps={closeIcon} onClick={() => updateCurrentErrorMessage('')} />
            </ErrorMessageContainer>
        </ErrorMessageOverlay>
    );
};
