import { Action, action } from 'easy-peasy';
import { IAuthor } from 'ui.shared';

/**
 * Interface for the global data model.
 */
export interface IGlobalDataModel {
    /**
     * The current selected navigation key.
     */
    authors: IAuthor[];

    /**
     * Action to update the current selected navigation key.
     */
    updateAuthors: Action<IGlobalDataModel, IAuthor[]>;

    /**
     * The current occured error message.
     */
    currentErrorMessage: string;

    /**
     * Action to update the current occured error message.
     */
    updateCurrentErrorMessage: Action<IGlobalDataModel, string>;
}

/**
 * Initial state of the global data model.
 */
export const GlobalDataModel: IGlobalDataModel = {
    authors: [],
    updateAuthors: action((state, payload) => {
        state.authors = payload;
    }),
    currentErrorMessage: '',
    updateCurrentErrorMessage: action((state, payload) => {
        state.currentErrorMessage = payload;
    }),
};
