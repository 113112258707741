import { getTheme } from '@fluentui/react';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ITag } from 'ui.shared';
import { IBackgroundColor, IFontColor } from 'ui.shared';
import { IArticle } from 'ui.shared';
import { fetchApi } from '../helper/ApiHelper';
import { useStoreActions } from '../store/Store';
import { ArticleTimelinePanel } from '../common/ArticleTimelinePanel';

const ArticleSubTagSelectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: max-content;
`;

interface ISelectionCard {
    /**
     * The background photo.
     */
    photo?: any;
}

const SelectionCard = styled.div<ISelectionCard & IBackgroundColor & IFontColor>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    background-size: cover;
    margin: 10px;
    min-height: max-content;
    cursor: pointer;
    box-shadow: 0 1px 1px rgba(16, 27, 30, 0.15), 0 2px 2px rgba(16, 27, 30, 0.15), 0 4px 4px rgba(16, 27, 30, 0.15), 0 8px 8px rgba(16, 27, 30, 0.15),
        0 16px 16px rgba(16, 27, 30, 0.15);
    border-radius: 4px;
    background-color: ${(props) => props.backgroundColor};
    background-image: url(${(props) => props.photo});
    background-position: bottom center;
    color: ${(props) => props.fontColor};
    font-size: 20px;
    font-weight: bold;
    &:hover {
        transform: scale(1.015);
    }
`;

const FlexRowWrapper = styled.div`
    display: flex;
    flex: 1;
    @media only screen and (max-width: 760px) {
        flex-direction: column;
    }
`;

const TagTextWrapper = styled.div`
    padding: 5px;
`;

/**
 * Interface for the props of the article tag sub view.
 */
interface IArticleSubTagSelection {
    /**
     * The primary tag.
     */
    primaryTag: ITag;

    /**
     * The first Tag to render.
     */
    firstTag: ITag;

    /**
     * The second tag to render.
     */
    secondTag: ITag;

    /**
     * The third tag to render.
     */
    thirdTag: ITag;

    /**
     * The fourth tag to render.
     */
    fourthTag: ITag;

    /**
     * The first category selection card background image.
     */
    firstCategoryImage: any;

    /**
     * The second category selection card background image.
     */
    secondCategoryImage: any;

    /**
     * The third category selection card background image.
     */
    thirdCategoryImage: any;

    /**
     * The fourth category selection card background image.
     */
    fourthCategoryImage: any;
}

/**
 * Component that displays a selection of article tags and renders an article timeline based on the chosen tag.
 */
export const ArticleSubTagSelection: FunctionComponent<IArticleSubTagSelection> = (props) => {
    const theme = getTheme();

    /**
     * State of the currently selected sub tag.
     */
    const [selectedTag, setSelectedTag] = useState<ITag | undefined>(undefined);

    /**
     * Whether the panel that displays the article timeline is open or not.
     */
    const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);

    /**
     * State of the fetched articles.
     */
    const [articles, setArticles] = useState<IArticle[]>([]);

    /**
     * State of the current fetched page of the articles table.
     */
    const [currentPage, setCurrentPage] = useState<number>(1);

    /**
     * State of the maximum available pages of the articles table.
     */
    const [availablePages, setAvailablePages] = useState<number>(1);

    /**
     * Whether the article timeline is busy or not.
     */
    const [isArticleTimelineBusy, setIsArticleTimelineBusy] = useState<boolean>(false);

    /**
     * Store action to update the current occured error message.
     */
    const updateCurrentErrorMessage = useStoreActions((actions) => actions.GlobalDataModel.updateCurrentErrorMessage);

    /**
     * Fetch articles based on selected tag and the primary tag.
     */
    useEffect(() => {
        if (selectedTag) {
            const fetchData = async () => {
                try {
                    setIsArticleTimelineBusy(true);
                    const requestBody = {
                        itemsPerPage: 25,
                        pageToDeliver: currentPage,
                        tagsToInclude: [props.primaryTag, selectedTag],
                    };
                    const result = await fetchApi('Article/PagedByTags', 'POST', requestBody);
                    setAvailablePages(result.pagesAvailable);
                    setArticles(result.data);
                } catch (error) {
                    console.error(error);
                    updateCurrentErrorMessage('Beim holen der Artikel ist ein Fehler aufgetreten!');
                } finally {
                    setIsArticleTimelineBusy(false);
                }
            };
            fetchData();
        }
    }, [currentPage, setCurrentPage, selectedTag, setSelectedTag, props.primaryTag, updateCurrentErrorMessage]);

    /**
     * Close the panel.
     */
    const closePanel = () => {
        setSelectedTag(undefined);
        setArticles([]);
        setIsPanelOpen(false);
    };

    /**
     * The memorized data to render in the table.
     */
    const memorizedArticles = useMemo(() => articles, [articles]);

    return (
        <ArticleSubTagSelectionContainer>
            <ArticleTimelinePanel
                headerText={`${props.primaryTag.name}: ${selectedTag && selectedTag.name}`}
                isOpen={isPanelOpen}
                articles={memorizedArticles}
                closePanel={closePanel}
                currentPage={currentPage}
                availablePages={availablePages}
                isBusy={isArticleTimelineBusy}
                navigateBackward={() => setCurrentPage(currentPage - 1)}
                navigateForward={() => setCurrentPage(currentPage + 1)}
                navigateToEnd={() => setCurrentPage(availablePages)}
                navigateToStart={() => setCurrentPage(1)}
            />
            <FlexRowWrapper>
                <SelectionCard
                    photo={props.firstCategoryImage}
                    backgroundColor={theme.palette.neutralDark}
                    fontColor={theme.palette.white}
                    onClick={() => {
                        setSelectedTag(props.firstTag);
                        setIsPanelOpen(true);
                    }}
                >
                    <TagTextWrapper> {props.firstTag.name}</TagTextWrapper>
                </SelectionCard>
                <SelectionCard
                    photo={props.secondCategoryImage}
                    backgroundColor={theme.palette.neutralDark}
                    fontColor={theme.palette.white}
                    onClick={() => {
                        setSelectedTag(props.secondTag);
                        setIsPanelOpen(true);
                    }}
                >
                    <TagTextWrapper>{props.secondTag.name}</TagTextWrapper>
                </SelectionCard>
            </FlexRowWrapper>
            <FlexRowWrapper>
                <SelectionCard
                    photo={props.thirdCategoryImage}
                    backgroundColor={theme.palette.neutralDark}
                    fontColor={theme.palette.white}
                    onClick={() => {
                        setSelectedTag(props.thirdTag);
                        setIsPanelOpen(true);
                    }}
                >
                    <TagTextWrapper>{props.thirdTag.name}</TagTextWrapper>
                </SelectionCard>
                <SelectionCard
                    photo={props.fourthCategoryImage}
                    backgroundColor={theme.palette.neutralDark}
                    fontColor={theme.palette.white}
                    onClick={() => {
                        setSelectedTag(props.fourthTag);
                        setIsPanelOpen(true);
                    }}
                >
                    <TagTextWrapper>{props.fourthTag.name}</TagTextWrapper>
                </SelectionCard>
            </FlexRowWrapper>
        </ArticleSubTagSelectionContainer>
    );
};
