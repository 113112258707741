import { _options } from '../index';

/**
 * Fetch the backend api.
 *
 * @param {string} relativePath The relative endpoint path.
 * @param {string} method The http request method.
 * @param {any} requestBody The optional body content of the request.
 * @param {AbortSignal} signal The optional signal to abort the request.
 */
export const fetchApi = async (relativePath: string, method?: string, requestBody?: any, signal?: AbortSignal) => {
    const apiOptions = (await _options).apiOptions;
    const body = JSON.stringify(requestBody);
    const result = await fetch(`${apiOptions.baseUrl}/${relativePath}`, {
        method,
        headers: {
            'Content-Type': 'application/json',
        },
        body,
        signal,
    });
    if (result.status !== 200) {
        throw result.status;
    }
    return result.json();
};
