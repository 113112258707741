import {
  DefaultButton,
  getTheme,
  IButtonStyles,
  IconButton,
  IIconProps,
  IIconStyles,
  Panel,
  PanelType,
} from "@fluentui/react";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { fetchApi } from "../helper/ApiHelper";
import { IArticle, IFontColor } from "ui.shared";
import { IBackgroundColor } from "ui.shared";
import History from "../navigation/BrowserHistory";
import { formatDateWithoutTime } from "ui.shared/lib/helper/DateFormatHelper";
import { TagDisplay } from "../common/TagDisplay";
import { ITag } from "ui.shared";
import { BusyIndicator } from "../common/BusyIndicator";
import { useStoreActions } from "../store/Store";
import ForestHD from "../assets/forest_hd.jpg";
import ForestBig from "../assets/forest_big.jpg";
import { openUrlInNewTab } from "../helper/UrlHelper";
import parse from "html-react-parser";
import { returnSecureHtml } from "../helper/HtmlHelper";

const SingleArticleViewContainer = styled.div`
  display: flex;
  flex: 1;
  max-height: 100%;
  justify-content: center;
  position: relative;
  padding: 20px;
  background-image: url(${ForestBig});
  background-repeat: no-repeat;
  background-size: cover;
  @media only screen and (max-width: 2000px) {
    background-image: url(${ForestHD});
  }
  @media only screen and (max-width: 850px) {
    padding: 15px;
  }
  @media only screen and (max-width: 800px) {
    padding: 10px;
  }
  @media only screen and (max-width: 780px) {
    padding: 0;
  }
  @media only screen and (max-height: 600px) {
    height: max-content;
    min-height: max-content;
    width: 100%;
    min-width: 100%;
    padding: 0;
  }
`;

const ArticleContainer = styled.div<IBackgroundColor>`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;
  flex: 1;
  max-width: 1100px;
  width: 70%;
  box-shadow: 0 1px 1px rgba(16, 27, 30, 0.15), 0 2px 2px rgba(16, 27, 30, 0.15),
    0 4px 4px rgba(16, 27, 30, 0.15), 0 8px 8px rgba(16, 27, 30, 0.15),
    0 16px 16px rgba(16, 27, 30, 0.15);
  border-radius: 4px;
  background-color: ${(props) => props.backgroundColor};
  @media only screen and (max-height: 600px) {
    height: max-content;
    min-height: max-content;
    width: 100%;
    min-width: 100%;
    padding: 0;
  }
  @media only screen and (max-width: 450px) {
    padding: 0;
  }
`;

const ControlsContainer = styled.div`
  position: absolute;
  padding: 10px;
`;

const TitleWrapper = styled.div<IFontColor>`
  display: flex;
  justify-content: center;
  font-size: 26px;
  font-weight: bold;
  align-self: center;
  width: 672px;
  padding: 10px 0;
  color: ${(props) => props.fontColor};
  @media only screen and (max-width: 820px) {
    width: 616px;
  }
  @media only screen and (max-width: 760px) {
    width: 560px;
  }
  @media only screen and (max-width: 710px) {
    width: 448px;
  }
  @media only screen and (max-width: 555px) {
    width: 336px;
  }
  @media only screen and (max-width: 450px) {
    width: 250px;
  }
`;

const ImageSlideshowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: max-content;
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 20px 15px 0 15px;
`;

const ArticleImage = styled.img`
  width: 300px;
  margin: 0 15px;
  cursor: pointer;
  &:hover {
    transform: scale(1.015);
  }
  @media only screen and (max-width: 405px) {
    width: 275px;
  }
  @media only screen and (max-width: 370px) {
    width: 250px;
  }
  @media only screen and (max-width: 340px) {
    width: 225px;
  }
  @media only screen and (max-width: 320px) {
    width: 200px;
  }
`;

const PreviewImage = styled.img`
  width: 300px;
  margin: 0 15px;
  opacity: 0.5;
  cursor: pointer;
  @media only screen and (max-width: 1170px) {
    display: none;
  }
`;

const Divider = styled.div<IBackgroundColor>`
  width: 100%;
  min-height: 1px;
  margin-top: 20px;
  background-color: ${(props) => props.backgroundColor};
`;

const MetadataWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const FullSizeImage = styled.img`
  max-height: calc(100vh - 150px);
  max-width: calc(100% - 75px);
`;

const MetadataContainer = styled.div`
  display: flex;
  width: 672px;
  @media only screen and (max-width: 700px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media only screen and (max-width: 820px) {
    width: 616px;
  }
  @media only screen and (max-width: 760px) {
    width: 560px;
  }
  @media only screen and (max-width: 710px) {
    width: 448px;
  }
  @media only screen and (max-width: 555px) {
    width: 336px;
  }
  @media only screen and (max-width: 375px) {
    width: 308px;
  }
`;

const TagDisplayWrapper = styled.div`
  display: flex;
  height: max-content;
  margin-right: 20px;
  @media only screen and (max-width: 700px) {
    margin: 0 20px 20px 0;
  }
`;

const ShortTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ShortText = styled.div`
  padding-top: 20px;
  font-weight: bold;
  width: 672px;
  text-align: left;
  @media only screen and (max-width: 820px) {
    width: 616px;
  }
  @media only screen and (max-width: 760px) {
    width: 560px;
  }
  @media only screen and (max-width: 710px) {
    width: 448px;
  }
  @media only screen and (max-width: 555px) {
    width: 336px;
  }
  @media only screen and (max-width: 375px) {
    width: 308px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LongTextWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 672px;
  text-align: left;
  min-height: max-content;
  padding-top: 20px;
  @media only screen and (max-width: 820px) {
    width: 616px;
  }
  @media only screen and (max-width: 760px) {
    width: 560px;
  }
  @media only screen and (max-width: 710px) {
    width: 448px;
  }
  @media only screen and (max-width: 555px) {
    width: 336px;
  }
  @media only screen and (max-width: 375px) {
    width: 308px;
  }
`;

const ArticleVideoWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  min-height: max-content;
`;

const FlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ArticleVideoFrame = styled.iframe`
  width: 672px;
  height: 378px;
  @media only screen and (max-width: 820px) {
    width: 616px;
    height: 347px;
  }
  @media only screen and (max-width: 760px) {
    width: 560px;
    height: 315px;
  }
  @media only screen and (max-width: 710px) {
    width: 448px;
    height: 252px;
  }
  @media only screen and (max-width: 555px) {
    width: 336px;
    height: 189px;
  }
  @media only screen and (max-width: 375px) {
    width: 308px;
    height: 173px;
  }
`;

const CreationTimeWrapper = styled.div`
  display: flex;
  margin-left: auto;
`;

const ImagePanelBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const PanelButtonContainer = styled.div`
  display: flex;
  padding: 15px;
`;

/**
 * The params that can be passed in the route.
 */
interface IParams {
  /**
   * The id of the article.
   */
  id: string | undefined;
}

interface ILocation {
  article: IArticle | undefined;
}

/**
 * View component to display a single article.
 */
export const SingleArticleView: FunctionComponent = () => {
  const theme = getTheme();
  const params = useParams<IParams>();
  const location = useLocation<ILocation>();

  /**
   * The state of the article to display.
   */
  const [article, setArticle] = useState<IArticle | undefined>(undefined);

  /**
   * The state of the index of the image to show in the slideshow.
   */
  const [imageNumberToShow, setImageNumberToShow] = useState<number>(0);

  /**
   * Whether the image panel is open or not.
   */
  const [isImagePanelOpen, setIsImagePanelOpen] = useState<boolean>(false);

  /**
   * Whether this component is busy or not.
   */
  const [isBusy, setIsBusy] = useState<boolean>(false);

  /**
   * Store action to update the current occured error message.
   */
  const updateCurrentErrorMessage = useStoreActions(
    (actions) => actions.GlobalDataModel.updateCurrentErrorMessage
  );

  /**
   * Set the article.
   * Take the article from location,
   * if not existend, fetch the article by it's id.
   */
  useEffect(() => {
    if (location.state && location.state.article) {
      setArticle(location.state.article);
    } else {
      const fetchArticle = async () => {
        try {
          setIsBusy(true);
          const result = await fetchApi(
            `Article/GetById?id=${params.id}`,
            "GET"
          );
          setArticle(result);
        } catch (error) {
          console.error(error);
          if (error === 404) {
            History.push("/notfound");
          }
          updateCurrentErrorMessage(
            "Beim holen des Artikels ist ein Fehler aufgetreten!"
          );
        } finally {
          setIsBusy(false);
        }
      };
      fetchArticle();
    }
  }, [location.state, params.id, updateCurrentErrorMessage]);

  /**
   * Icon for the navigate back button.
   */
  const leftIcon: IIconProps = {
    iconName: "ChevronLeftMed",
  };

  /**
   * Icon for the navigate forward button.
   */
  const rightIcon: IIconProps = {
    iconName: "ChevronRightMed",
  };

  /**
   * Styles for the icons that are used in the view navigation button.
   */
  const viewNavigationIconStyles: IIconStyles = {
    root: {
      fontSize: 20,
    },
  };

  /**
   * Icon of the button to navigate back to the previous view.
   */
  const backButtonIcon: IIconProps = {
    iconName: "NavigateBack",
    styles: viewNavigationIconStyles,
  };

  /**
   * Styles of the button that are used in the view navigation.
   */
  const viewNavigationButtonStyles: IButtonStyles = {
    root: {
      minHeight: 35,
      minWidth: 35,
      marginRight: 10,
      color: theme.palette.themePrimary,
      borderColor: theme.palette.themePrimary,
    },
    rootHovered: {
      color: theme.palette.themePrimary,
    },
    rootPressed: {
      color: theme.palette.themePrimary,
    },
  };

  /**
   * Determine the next image of the slideshow.
   * @param direction -1 for one image backwards, 1 for one image forwards.
   */
  const determineNextImage = (direction: number): number => {
    const newValue = imageNumberToShow + direction;
    if (!article || !article.pictures) {
      return -1;
    }
    if (newValue >= article.pictures.length) {
      return 0;
    }
    if (newValue < 0) {
      return article.pictures.length - 1;
    }
    return newValue;
  };

  /**
   * Navigate to the next image of the slideshow.
   * @param direction -1 for one image backwards, 1 for one image forwards.
   */
  const navigateToNextImage = (direction: number): void => {
    const newValue = determineNextImage(direction);
    if (newValue === -1) {
      return;
    }
    setImageNumberToShow(newValue);
  };

  return (
    <SingleArticleViewContainer>
      <BusyIndicator isBusy={isBusy} />
      {article && (
        <ArticleContainer backgroundColor={theme.palette.white}>
          <ControlsContainer>
            <IconButton
              iconProps={backButtonIcon}
              styles={viewNavigationButtonStyles}
              onClick={() => {
                if (History.location.state) {
                  History.goBack();
                } else {
                  History.push("/");
                }
              }}
            />
          </ControlsContainer>
          <TitleWrapper fontColor={theme.palette.themePrimary}>
            {article.title}
          </TitleWrapper>
          {article.pictures && article.pictures.length > 0 && (
            <ImageSlideshowContainer>
              <Panel
                isOpen={isImagePanelOpen}
                onDismiss={() => setIsImagePanelOpen(false)}
                type={PanelType.custom}
                closeButtonAriaLabel="Close"
              >
                <ImagePanelBody>
                  <ImageContainer>
                    <IconButton
                      disabled={article.pictures.length <= 1}
                      iconProps={leftIcon}
                      onClick={() => navigateToNextImage(-1)}
                    />
                    <FullSizeImage
                      src={article.pictures[imageNumberToShow].pictureUrl}
                      alt="Fehler"
                    />
                    <IconButton
                      disabled={article.pictures.length <= 1}
                      iconProps={rightIcon}
                      onClick={() => navigateToNextImage(1)}
                    />
                  </ImageContainer>
                  <PanelButtonContainer>
                    <DefaultButton
                      text="Öffne das Originalbild"
                      iconProps={{ iconName: "OpenInNewTab" }}
                      onClick={() => {
                        if (article && article.pictures) {
                          openUrlInNewTab(
                            article.pictures[imageNumberToShow].pictureUrl
                          );
                        }
                      }}
                    />
                  </PanelButtonContainer>
                </ImagePanelBody>
              </Panel>
              <IconButton
                disabled={article.pictures.length <= 1}
                iconProps={leftIcon}
                onClick={() => navigateToNextImage(-1)}
              />
              <PreviewImage
                hidden={article.pictures.length <= 1}
                src={article.pictures[determineNextImage(-1)].previewPictureUrl}
                alt="Fehler"
                onClick={() => navigateToNextImage(-1)}
              />
              <ArticleImage
                src={article.pictures[imageNumberToShow].previewPictureUrl}
                alt="Fehler"
                onClick={() => setIsImagePanelOpen(true)}
              />
              <PreviewImage
                hidden={article.pictures.length <= 1}
                src={article.pictures[determineNextImage(1)].previewPictureUrl}
                alt="Fehler"
                onClick={() => navigateToNextImage(1)}
              />
              <IconButton
                disabled={article.pictures.length <= 1}
                iconProps={rightIcon}
                onClick={() => navigateToNextImage(1)}
              />
            </ImageSlideshowContainer>
          )}
          <Divider backgroundColor={theme.palette.neutralLighterAlt} />
          <BottomContainer>
            <MetadataWrapper>
              <MetadataContainer>
                {article.tags && (
                  <FlexRow>
                    {article.tags.map((tag: ITag, i: number) => (
                      <TagDisplayWrapper key={i}>
                        <TagDisplay text={tag.name} />
                      </TagDisplayWrapper>
                    ))}
                  </FlexRow>
                )}
                {article.creationTime && (
                  <CreationTimeWrapper>
                    {formatDateWithoutTime(article.creationTime)}
                  </CreationTimeWrapper>
                )}
              </MetadataContainer>
            </MetadataWrapper>
            <ShortTextWrapper>
              <ShortText>{article.shortText}</ShortText>
            </ShortTextWrapper>
            <ContentContainer>
              <LongTextWrapper>
                {parse(returnSecureHtml(article.longText))}
              </LongTextWrapper>
              {article.video && (
                <ArticleVideoWrapper>
                  <ArticleVideoFrame
                    src={`https://www.youtube-nocookie.com/embed/${article.video?.youTubeCode}`}
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </ArticleVideoWrapper>
              )}
            </ContentContainer>
          </BottomContainer>
        </ArticleContainer>
      )}
    </SingleArticleViewContainer>
  );
};
