import { getTheme, IButtonStyles, PrimaryButton } from "@fluentui/react";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { IArticle, IPicture } from "ui.shared";
import { IBorderColor, IFontColor } from "ui.shared";
import { PaginationControls } from "./PaginationControls";
import { formatDateWithoutTime } from "ui.shared/lib/helper/DateFormatHelper";
import PlaceholderImage from "../assets/placeholder.jpg";
import History from "../navigation/BrowserHistory";
import { ITag } from "ui.shared";
import { TagDisplay } from "./TagDisplay";

const ArticleTimelineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const ArticleTimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
`;

const ArticleTimelineElementWrapper = styled.div<IBorderColor>`
  display: flex;
  width: 100%;
  min-height: max-content;
  border-bottom: 1px solid ${(props) => props.borderColor};
`;

const ArticleTimelineElementContainer = styled.div`
  display: flex;
  flex: 1;
  margin: 20px;
  &:hover {
    transform: scale(1.015);
  }
  @media only screen and (max-width: 740px) {
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ArticleImage = styled.img`
  width: 300px;
  @media only screen and (max-width: 405px) {
    width: 275px;
  }
  @media only screen and (max-width: 370px) {
    width: 250px;
  }
  @media only screen and (max-width: 340px) {
    width: 225px;
  }
  @media only screen and (max-width: 320px) {
    width: 200px;
  }
`;

const ArticleInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 15px;
`;

const ArticleTitleWrapper = styled.div<IFontColor>`
  display: flex;
  font-size: 20px;
  font-weight: bold;
  color: ${(props) => props.fontColor};
  @media only screen and (max-width: 740px) {
    padding-top: 15px;
    justify-content: center;
  }
`;

const ArticleAdditionalInformationContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
  padding: 10px 0;
`;

const TagDisplayWrapper = styled.div`
  margin-left: 20px;
`;

const ArticleShortTextContainer = styled.div`
  padding-bottom: 20px;
  text-align: left;
`;

const ArticleControlsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: auto;
`;

const PaginationControlsWrapper = styled.div<IBorderColor>`
  margin-top: auto;
  padding: 15px;
  border-top: 1px solid ${(props) => props.borderColor};
`;

/**
 * Interface for the properties of the article timeline component.
 */
interface IArticleTimeline {
  /**
   * The articles to display.
   */
  articles: IArticle[];

  /**
   * The number of the current displayed page.
   */
  currentPage: number;

  /**
   * The maximum number of available pages.
   */
  availablePages: number;

  /**
   * Callback to navigate one page backward.
   */
  navigateBackward: () => void;

  /**
   * Callback to navigate one page forward.
   */
  navigateForward: () => void;

  /**
   * Callback to navigate to the first page.
   */
  navigateToStart: () => void;

  /**
   * Callback to navigate to the last page.
   */
  navigateToEnd: () => void;
}

/**
 * Timeline component to show articles.
 */
export const ArticleTimeline: FunctionComponent<IArticleTimeline> = (props) => {
  const theme = getTheme();

  /**
   * Styles of the button to open an article.
   */
  const buttonStyles: IButtonStyles = {
    rootHovered: {
      transform: "unset",
    },
  };

  return (
    <ArticleTimelineWrapper>
      <ArticleTimelineContainer>
        {props.articles.map((article: IArticle, i: number) => {
          let previewImage: IPicture | undefined = undefined;
          if (
            article.previewPictureId &&
            article.pictures &&
            article.pictures.length > 0
          ) {
            previewImage = article.pictures.find(
              (pic) => pic.id === article.previewPictureId
            );
          }
          return (
            <ArticleTimelineElementWrapper
              key={i}
              borderColor={theme.palette.neutralLighterAlt}
            >
              <ArticleTimelineElementContainer>
                <ImageContainer>
                  {article.pictures && article.pictures.length > 0 ? (
                    <ArticleImage
                      src={
                        previewImage
                          ? previewImage.previewPictureUrl
                          : article.pictures[0].previewPictureUrl
                      }
                      alt="Fehler"
                    />
                  ) : (
                    <ArticleImage src={PlaceholderImage} alt="Fehler" />
                  )}
                </ImageContainer>
                <ArticleInformationContainer>
                  <ArticleTitleWrapper fontColor={theme.palette.themePrimary}>
                    {article.title}
                  </ArticleTitleWrapper>
                  <ArticleAdditionalInformationContainer>
                    {article.creationTime && (
                      <div>{formatDateWithoutTime(article.creationTime)}</div>
                    )}
                    {article.tags &&
                      article.tags.map((tag: ITag, i: number) => (
                        <TagDisplayWrapper key={i}>
                          <TagDisplay text={tag.name} />
                        </TagDisplayWrapper>
                      ))}
                  </ArticleAdditionalInformationContainer>
                  <ArticleShortTextContainer>
                    {article.shortText}
                  </ArticleShortTextContainer>
                  <ArticleControlsContainer>
                    <PrimaryButton
                      styles={buttonStyles}
                      text="weiterlesen"
                      onClick={() =>
                        History.push({
                          pathname: `/article/${article.id}`,
                          state: { article: article },
                        })
                      }
                    />
                  </ArticleControlsContainer>
                </ArticleInformationContainer>
              </ArticleTimelineElementContainer>
            </ArticleTimelineElementWrapper>
          );
        })}
      </ArticleTimelineContainer>
      {props.availablePages > 1 && (
        <PaginationControlsWrapper
          borderColor={theme.palette.neutralLighterAlt}
        >
          <PaginationControls
            currentPage={props.currentPage}
            availablePages={props.availablePages}
            navigateBackward={props.navigateBackward}
            navigateForward={props.navigateForward}
            navigateToEnd={props.navigateToEnd}
            navigateToStart={props.navigateToStart}
          />
        </PaginationControlsWrapper>
      )}
    </ArticleTimelineWrapper>
  );
};
