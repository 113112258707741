import { ISpinnerStyles, Spinner } from '@fluentui/react';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface IBusyOverlay {
    /**
     * The value of the display attribute.
     */
    display: string;
}

const BusyOverlay = styled.div<IBusyOverlay>`
    display: ${(props) => props.display};
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.99);
    z-index: 1;
`;

/**
 * Interface for the properties ot the busy indicator.
 */
interface IBusyIndicator {
    /**
     * Whether the busy indicator is visible or not.
     */
    isBusy: boolean;
}

/**
 * Basic component to indicate async operations.
 * NOTE: To use place this component inside a container that has set 'position: relative'. This component then will take 100% of that container space and overlaps it.
 */
export const BusyIndicator: FunctionComponent<IBusyIndicator> = (props) => {
    /**
     * Styles of the busy spinner.
     */
    const spinnerStyles: ISpinnerStyles = {
        root: {
            height: '60px',
            width: '60px',
        },
        circle: {
            height: '50px',
            width: '50px',
            borderWidth: '2px',
        },
    };

    return (
        <BusyOverlay display={props.isBusy ? 'flex' : 'none'}>
            <Spinner styles={spinnerStyles} />
        </BusyOverlay>
    );
};
