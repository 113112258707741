/**
 * Formats a date to string.
 * @param date The date to format.
 */
export const formatDateWithTime = (date: Date): string => {
    if (typeof date === 'string') {
        date = new Date(date);
    }
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${('0' + day).slice(-2)}.${('0' + month).slice(-2)}.${year} ${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)} Uhr`;
};

/**
 * Formats a date to string without hours and minutes.
 * @param date The date to format.
 */
export const formatDateWithoutTime = (date: Date): string => {
    if (typeof date === 'string') {
        date = new Date(date);
    }
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${('0' + day).slice(-2)}.${('0' + month).slice(-2)}.${year}`;
};
