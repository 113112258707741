import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { getTheme, IColumn, TooltipHost } from '@fluentui/react';
import { IBackgroundColor } from 'ui.shared/lib/types/StyledComponentsTypes';
import { useStoreActions, useStoreState } from '../store/Store';
import { IAuthorDonator } from 'ui.shared/lib/types/IAuthorDonator';
import { fetchApi } from '../helper/ApiHelper';
import { openUrlInNewTab } from '../helper/UrlHelper';
import { Table } from '../common/Table';
import FacebookLogo from '../assets/facebook_logo.png';
import InstagramLogo from '../assets/instagram_logo.jpg';
import { UrlButton } from '../common/UrlButton';

const EveryKilometerCountsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
`;

const SocialMediaIcon = styled.img`
    height: 25px;
    width: auto;
    cursor: pointer;
`;

const HeadlineWrapper = styled.div`
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 15px;
`;

const TextCard = styled.div<IBackgroundColor>`
    display: flex;
    flex-direction: column;
    min-height: max-content;
    margin: 0 20px 20px 20px;
    padding: 15px;
    box-shadow: 0 1px 1px rgba(16, 27, 30, 0.15), 0 2px 2px rgba(16, 27, 30, 0.15), 0 4px 4px rgba(16, 27, 30, 0.15), 0 8px 8px rgba(16, 27, 30, 0.15),
        0 16px 16px rgba(16, 27, 30, 0.15);
    border-radius: 4px;
    background-color: ${(props) => props.backgroundColor};
`;

const TableWrapper = styled.div`
    display: flex;
    flex: 1;
    padding: 0 20px 20px 20px;
    overflow: hidden;
    @media (orientation: portrait) {
        min-height: 340px;
        height: 340px;
    }
    @media only screen and (max-height: 1079px) {
        min-height: 340px;
        height: 340px;
    }
`;

const FlexCard = styled.div<IBackgroundColor>`
    display: flex;
    flex-direction: column;
    min-height: max-content;
    flex: 1;
    margin: 0 20px 20px 20px;
    padding: 25px;
    box-shadow: 0 1px 1px rgba(16, 27, 30, 0.15), 0 2px 2px rgba(16, 27, 30, 0.15), 0 4px 4px rgba(16, 27, 30, 0.15), 0 8px 8px rgba(16, 27, 30, 0.15),
        0 16px 16px rgba(16, 27, 30, 0.15);
    border-radius: 4px;
    background-color: ${(props) => props.backgroundColor};
`;

const Text = styled.div`
    display: flex;
    text-align: left;
    word-wrap: break-word;
`;

const KilometerDisplay = styled.div`
    margin-left: auto;
    cursor: pointer;
`;

const TextHeadline = styled.div`
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
`;

const KilometerCardsContainer = styled.div`
    display: flex;
    min-height: max-content;
    @media only screen and (max-width: 1024px) {
        flex-direction: column;
    }
`;

/**
 * View component for the every kilometer counts view.
 */
export const EveryKilometerCountsView: FunctionComponent = () => {
    const theme = getTheme();

    /**
     * Store state of the currently available authors.
     */
    const authors = useStoreState((state) => state.GlobalDataModel.authors);

    /**
     * State of the fetched donators.
     */
    const [donators, setDonators] = useState<IAuthorDonator[]>([]);

    /**
     * State of the current fetched page of the donators list.
     */
    const [currentPage, setCurrentPage] = useState<number>(1);

    /**
     * State of the maximum available pages of the donators list.
     */
    const [availablePages, setAvailablePages] = useState<number>(1);

    /**
     * Whether the donators list is busy or not.
     */
    const [isDonatorsListBusy, setIsDonatorsListBusy] = useState<boolean>(false);

    /**
     * Store action to update the current occured error message.
     */
    const updateCurrentErrorMessage = useStoreActions((actions) => actions.GlobalDataModel.updateCurrentErrorMessage);

    /**
     * Fetch the donators.
     */
    useEffect(() => {
        const fetchDonators = async () => {
            try {
                setIsDonatorsListBusy(true);
                const requestBody = {
                    itemsPerPage: 50,
                    pageToDeliver: currentPage,
                };
                const result = await fetchApi('AuthorDonator/ActivePaged', 'POST', requestBody);
                setAvailablePages(result.pagesAvailable);
                setDonators(result.data);
            } catch (error) {
                console.error(error);
                updateCurrentErrorMessage('Beim holen der Spender ist ein Fehler aufgetreten!');
            } finally {
                setIsDonatorsListBusy(false);
            }
        };
        fetchDonators();
    }, [updateCurrentErrorMessage, currentPage, setCurrentPage]);

    /**
     * Columns of the donator table.
     */
    const donatorTableColumns: IColumn[] = [
        {
            key: 'donator.displayName',
            name: 'Spender',
            fieldName: 'donator.displayName',
            minWidth: 200,
            maxWidth: 1000,
            isResizable: true,
            onRender: (item: IAuthorDonator) => {
                if (item && item.donator && item.donator.displayName) {
                    return item.donator.displayName;
                } else {
                    return null;
                }
            },
        },
        {
            key: 'donator.link',
            name: 'Webseite',
            fieldName: 'donator.link',
            minWidth: 75,
            maxWidth: 75,
            isResizable: true,
            onRender: (item: IAuthorDonator) => {
                if (item && item.donator && item.donator.link) {
                    return <UrlButton url={item.donator.link} />;
                } else {
                    return null;
                }
            },
        },
        {
            key: 'donator.facebookProfile',
            name: 'Facebook',
            fieldName: 'donator.facebookProfile',
            minWidth: 75,
            maxWidth: 75,
            isResizable: true,
            onRender: (item: IAuthorDonator) => {
                if (item && item.donator && item.donator.facebookProfile) {
                    return (
                        <TooltipHost content={item.donator.facebookProfile}>
                            <SocialMediaIcon src={FacebookLogo} alt="" onClick={() => openUrlInNewTab(item.donator.facebookProfile)} />
                        </TooltipHost>
                    );
                } else {
                    return null;
                }
            },
        },
        {
            key: 'donator.instagramProfile',
            name: 'Instagram',
            fieldName: 'donator.facebookProfile',
            minWidth: 75,
            maxWidth: 75,
            isResizable: true,
            onRender: (item: IAuthorDonator) => {
                if (item && item.donator && item.donator.instagramProfile) {
                    return (
                        <TooltipHost content={item.donator.instagramProfile}>
                            <SocialMediaIcon src={InstagramLogo} alt="" onClick={() => openUrlInNewTab(item.donator.instagramProfile)} />
                        </TooltipHost>
                    );
                } else {
                    return null;
                }
            },
        },
        {
            key: 'centPerKilometer',
            name: 'Cent pro km',
            fieldName: 'centPerKilometer',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
        },
        {
            key: 'author.displayName',
            name: 'Pate von',
            fieldName: 'author.displayName',
            minWidth: 100,
            maxWidth: 1000,
            isResizable: true,
            onRender: (item: IAuthorDonator) => {
                if (item && item.author && item.author.displayName) {
                    return item.author.displayName;
                } else {
                    return null;
                }
            },
        },
    ];

    return (
        <EveryKilometerCountsContainer>
            <TextCard backgroundColor={theme.palette.white}>
                <HeadlineWrapper>Jeder Kilometer zählt</HeadlineWrapper>
                <Text>
                    Wir fahren – ihr spendet. Gemeinsam tun wir Gutes. Wie funktioniert das eigentlich? Ihr sucht euch einen Kurbelix aus und werdet ganz persönlicher Pate. Für
                    jeden gefahrenen Kilometer spendet ihr am Ende des Jahres einen Cent für den guten Zweck. Selbstverständlich dürft ihr auch mehr spenden. Alle Spendengelder
                    landen in einem großen Topf und werden durch zwei geteilt. Jeder Kurbelix hat dann die Ehre ein Projekt oder Verein seiner Wahl mit einem stolzen Sümmchen zu
                    unterstützen. Eure Spenden werden eins zu eins weitergegeben, denn jeder Cent und damit jeder Kilometer wird gebraucht. Gebraucht von engagierten Projekten und
                    Vereinen IM Harz. Wir geben euch daher das Versprechen, dass eure Spenden im Harz bleiben. Wir helfen mit unsere Heimat zu erhalten. Ihr habt also die einmalige
                    Gelegenheit uns zwei Kurbelixe mal so richtig durch die Gegend zu scheuchen. Entdeckt mit uns neue Wege und besondere Plätze. Den aktuellen Kilometerstand eures
                    Paten könnt ihr übrigens jederzeit auf unserer Startseite mitverfolgen. Auch die komplette Spendenzusammensetzung werdet ihr hier nachvollziehen können. Ihr
                    wollt nun dabei sein, wollt durch unsere Kilometer etwas Gutes tun? Dann hinterlasst uns eine Nachricht. Wir erklären euch alle Einzelheiten.
                </Text>
            </TextCard>
            <KilometerCardsContainer>
                {authors && authors[0] && (
                    <FlexCard backgroundColor={theme.palette.white}>
                        <TextHeadline>{authors[0].displayName}</TextHeadline>
                        <Text>
                            <div>Aktueller Kilometerstand</div>
                            {authors[0].stravaProfile && (
                                <KilometerDisplay onClick={() => openUrlInNewTab(authors[0].stravaProfile)}>
                                    <u>Zum Strava Profil</u>
                                </KilometerDisplay>
                            )}
                        </Text>
                    </FlexCard>
                )}
                {authors && authors[1] && (
                    <FlexCard backgroundColor={theme.palette.white}>
                        <TextHeadline>{authors[1].displayName}</TextHeadline>
                        <Text>
                            <div>Aktueller Kilometerstand</div>
                            {authors[1].stravaProfile && (
                                <KilometerDisplay onClick={() => openUrlInNewTab(authors[1].stravaProfile)}>
                                    <u>Zum Strava Profil</u>
                                </KilometerDisplay>
                            )}
                        </Text>
                    </FlexCard>
                )}
            </KilometerCardsContainer>
            <TextCard backgroundColor={theme.palette.white}>
                <TextHeadline>Vielen lieben Dank an unsere Spender &#128077;</TextHeadline>
            </TextCard>
            <TableWrapper>
                <Table
                    isBusy={isDonatorsListBusy}
                    columns={donatorTableColumns}
                    data={donators}
                    currentPage={currentPage}
                    availablePages={availablePages}
                    navigateBackward={() => setCurrentPage(currentPage - 1)}
                    navigateForward={() => setCurrentPage(currentPage + 1)}
                    navigateToEnd={() => setCurrentPage(availablePages)}
                    navigateToStart={() => setCurrentPage(1)}
                />
            </TableWrapper>
        </EveryKilometerCountsContainer>
    );
};
