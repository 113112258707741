import React, { FunctionComponent } from 'react';
import { Router, Switch } from 'react-router-dom';
import History from './BrowserHistory';
import { RouteWrapper } from './RouteWrapper';
import { BasicLayout } from '../layout/BasicLayout';
import { FullscreenLayout } from '../layout/FullscreenLayout';
import { HomeView } from '../views/HomeView';
import { HarzLoveView } from '../views/HarzLoveView';
import { OnTourView } from '../views/OnTourView';
import { AtStartView } from '../views/AtStartView';
import { SingleArticleView } from '../views/SingleArticleView';
import { NotFoundView } from '../views/NotFoundView';
import { AboutUsView } from '../views/AboutUsView';
import { ReadingMaterialView } from '../views/ReadingMaterialView';
import { EveryKilometerCountsView } from '../views/EveryKilometerCountsView';
import { ImprintView } from '../views/ImprintView';
import { PrivacyView } from '../views/PrivacyView';
import ReactGA from 'react-ga';

/**
 * The routes component.
 */
export const Routes: FunctionComponent = () => {
    // Initialize google analytics page view tracking.
    History.listen((location) => {
        // Update users current page.
        ReactGA.set({ page: location.pathname });
        // Track the page view.
        ReactGA.pageview(location.pathname);
    });

    return (
        <Router history={History}>
            <Switch>
                <RouteWrapper exact path="/" component={HomeView} layout={BasicLayout} />
                <RouteWrapper exact path="/aboutus" component={AboutUsView} layout={BasicLayout} />
                <RouteWrapper exact path="/harzliebe" component={HarzLoveView} layout={BasicLayout} />
                <RouteWrapper exact path="/ontour" component={OnTourView} layout={BasicLayout} />
                <RouteWrapper exact path="/amstart" component={AtStartView} layout={BasicLayout} />
                <RouteWrapper exact path="/jederkmzaehlt" component={EveryKilometerCountsView} layout={BasicLayout} />
                <RouteWrapper exact path="/lesestoff" component={ReadingMaterialView} layout={BasicLayout} />
                {/**<RouteWrapper exact path="/outtakes" component={OuttakesView} layout={BasicLayout} />*/}
                {/**<RouteWrapper exact path="/gaestebuch" component={GuestbookView} layout={BasicLayout} />*/}
                <RouteWrapper exact path="/article/:id" component={SingleArticleView} layout={FullscreenLayout} />
                <RouteWrapper exact path="/impressum" component={ImprintView} layout={BasicLayout} />
                <RouteWrapper exact path="/datenschutz" component={PrivacyView} layout={BasicLayout} />
                <RouteWrapper exact path="/notfound" component={NotFoundView} layout={FullscreenLayout} />
                <RouteWrapper path="*" exact={true} component={NotFoundView} layout={FullscreenLayout} />
            </Switch>
        </Router>
    );
};
