import { getTheme } from '@fluentui/react';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ArticleSubTagSelection } from '../common/ArticleSubTagSelection';
import { IBackgroundColor } from 'ui.shared';
import RacingBike from '../assets/OnTour_RacingBike.jpg';
import Mtb from '../assets/OnTour_MTB.jpg';
import Hiking from '../assets/OnTour_Hiking.jpg';
import FavoritePlaces from '../assets/OnTour_FavoritePlaces.jpg';

const OnTourContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
`;

const HeadlineWrapper = styled.div<IBackgroundColor>`
    font-size: 20px;
    font-weight: bold;
    margin: 10px;
    padding: 15px;
    box-shadow: 0 1px 1px rgba(16, 27, 30, 0.15), 0 2px 2px rgba(16, 27, 30, 0.15), 0 4px 4px rgba(16, 27, 30, 0.15), 0 8px 8px rgba(16, 27, 30, 0.15),
        0 16px 16px rgba(16, 27, 30, 0.15);
    border-radius: 4px;
    text-align: center;
    background-color: ${(props) => props.backgroundColor};
`;

const ArticleSubTagSelectionWrapper = styled.div`
    display: flex;
    flex: 1;
`;

/**
 * View component for the on tour view.
 */
export const OnTourView: FunctionComponent = () => {
    const theme = getTheme();

    return (
        <OnTourContainer>
            <HeadlineWrapper backgroundColor={theme.palette.white}>Kurbelixe on Tour</HeadlineWrapper>
            <ArticleSubTagSelectionWrapper>
                <ArticleSubTagSelection
                    primaryTag={{ id: 3, name: 'On Tour' }}
                    firstTag={{ id: 7, name: 'Mountainbike' }}
                    secondTag={{ id: 8, name: 'Rennrad' }}
                    thirdTag={{ id: 9, name: 'Wandern' }}
                    fourthTag={{ id: 11, name: 'Lieblingsplätze' }}
                    firstCategoryImage={Mtb}
                    secondCategoryImage={RacingBike}
                    thirdCategoryImage={Hiking}
                    fourthCategoryImage={FavoritePlaces}
                />
            </ArticleSubTagSelectionWrapper>
        </OnTourContainer>
    );
};
