import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { IArticle } from 'ui.shared/lib/types/IArticle';
import { BusyIndicator } from './BusyIndicator';
import { ArticleTimeline } from './ArticleTimeline';
import { Panel, PanelType, DefaultButton } from '@fluentui/react';

const PanelBody = styled.div`
    position: relative;
    min-height: 100%;
`;

const PanelFooter = styled.div`
    display: flex;
    justify-content: flex-end;
`;

/**
 * Interface for the properties of this component.
 */
interface IArticleTimelinePanel {
    /**
     * Whether this panel is open or not.
     */
    isOpen: boolean;

    /**
     * The header text to display.
     */
    headerText: string;

    /**
     * Callback to close the panel.
     */
    closePanel: () => void;

    /**
     * Whether this component is busy or not.
     */
    isBusy: boolean;

    /**
     * The articles to display.
     */
    articles: IArticle[];

    /**
     * The number of the current displayed page.
     */
    currentPage: number;

    /**
     * The maximum number of available pages.
     */
    availablePages: number;

    /**
     * Callback to navigate one page backward.
     */
    navigateBackward: () => void;

    /**
     * Callback to navigate one page forward.
     */
    navigateForward: () => void;

    /**
     * Callback to navigate to the first page.
     */
    navigateToStart: () => void;

    /**
     * Callback to navigate to the last page.
     */
    navigateToEnd: () => void;
}

/**
 * Fluent UI panel that displays an article timeline.
 */
export const ArticleTimelinePanel: FunctionComponent<IArticleTimelinePanel> = (props) => {
    /**
     * Footer of the article timeline panel.
     */
    const onRenderFooterContent = () => (
        <PanelFooter>
            <DefaultButton text="Schließen" onClick={props.closePanel} />
        </PanelFooter>
    );

    /**
     * Body of the article timeline panel
     */
    const onRenderBody = () => (
        <PanelBody>
            <BusyIndicator isBusy={props.isBusy} />
            <ArticleTimeline
                articles={props.articles}
                currentPage={props.currentPage}
                availablePages={props.availablePages}
                navigateBackward={props.navigateBackward}
                navigateForward={props.navigateForward}
                navigateToEnd={props.navigateToEnd}
                navigateToStart={props.navigateToStart}
            />
        </PanelBody>
    );
    return (
        <Panel
            type={PanelType.extraLarge}
            headerText={props.headerText}
            isOpen={props.isOpen}
            isLightDismiss={true}
            onDismiss={props.closePanel}
            closeButtonAriaLabel="Schließen"
            onRenderFooterContent={onRenderFooterContent}
            onRenderBody={onRenderBody}
            isFooterAtBottom={true}
        />
    );
};
