import { getTheme } from '@fluentui/react';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { IArticle, IBackgroundColor } from 'ui.shared';
import { ArticleTimeline } from '../common/ArticleTimeline';
import { BusyIndicator } from '../common/BusyIndicator';
import { fetchApi } from '../helper/ApiHelper';
import { useStoreActions } from '../store/Store';
import { EventTimeline } from '../common/EventTimeline';
import { IEvent } from 'ui.shared/lib/types/IEvent';
import { ArticleTimelinePanel } from '../common/ArticleTimelinePanel';

const AtStartContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const HeadlineWrapper = styled.div<IBackgroundColor>`
    font-size: 20px;
    font-weight: bold;
    margin: 20px 20px 0 20px;
    padding: 15px;
    box-shadow: 0 1px 1px rgba(16, 27, 30, 0.15), 0 2px 2px rgba(16, 27, 30, 0.15), 0 4px 4px rgba(16, 27, 30, 0.15), 0 8px 8px rgba(16, 27, 30, 0.15),
        0 16px 16px rgba(16, 27, 30, 0.15);
    border-radius: 4px;
    text-align: center;
    background-color: ${(props) => props.backgroundColor};
`;

const ContentContainer = styled.div`
    display: flex;
    overflow: hidden;
    @media only screen and (max-width: 1400px) {
        flex-direction: column;
        overflow: unset;
    }
    @media (orientation: portrait) {
        overflow: unset;
        flex-direction: column;
    }
`;

const EventTimelineWrapper = styled.div<IBackgroundColor>`
    display: flex;
    flex: 1;
    margin: 20px 10px 20px 20px;
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    box-shadow: 0 1px 1px rgba(16, 27, 30, 0.15), 0 2px 2px rgba(16, 27, 30, 0.15), 0 4px 4px rgba(16, 27, 30, 0.15), 0 8px 8px rgba(16, 27, 30, 0.15),
        0 16px 16px rgba(16, 27, 30, 0.15);
    border-radius: 4px;
    background-color: ${(props) => props.backgroundColor};
    @media only screen and (max-width: 1400px) {
        margin: 20px 20px 10px 20px;
        overflow: unset;
        min-height: max-content;
        max-height: max-content;
        flex: unset;
    }
    @media (orientation: portrait) {
        overflow: unset;
        min-height: max-content;
        max-height: max-content;
        flex: unset;
    }
`;

const ArticleTimelineWrapper = styled.div<IBackgroundColor>`
    display: flex;
    flex: 1;
    margin: 20px 20px 20px 10px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 1px 1px rgba(16, 27, 30, 0.15), 0 2px 2px rgba(16, 27, 30, 0.15), 0 4px 4px rgba(16, 27, 30, 0.15), 0 8px 8px rgba(16, 27, 30, 0.15),
        0 16px 16px rgba(16, 27, 30, 0.15);
    border-radius: 4px;
    background-color: ${(props) => props.backgroundColor};
    @media only screen and (max-width: 1400px) {
        margin: 10px 20px 20px 20px;
        overflow: unset;
        min-height: max-content;
        max-height: max-content;
        flex: unset;
    }
    @media (orientation: portrait) {
        overflow: unset;
        min-height: max-content;
        max-height: max-content;
        flex: unset;
    }
`;

const OpenArticleTimelineText = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 15px;
    font-weight: bold;
    min-height: max-content;
    max-height: max-content;
    font-size: 18px;
    cursor: pointer;
    &:hover {
        transform: scale(1.015);
    }
`;

/**
 * View component for the at start view.
 */
export const AtStartView: FunctionComponent = () => {
    const theme = getTheme();

    /**
     * The current year.
     */
    const currentYear = new Date().getFullYear();

    /**
     * State of the fetched articles.
     */
    const [articles, setArticles] = useState<IArticle[]>([]);

    /**
     * State of the fetched events.
     */
    const [events, setEvents] = useState<IEvent[]>([]);

    /**
     * State of the current fetched page of the articles table.
     */
    const [currentPage, setCurrentPage] = useState<number>(1);

    /**
     * State of the maximum available pages of the articles table.
     */
    const [availablePages, setAvailablePages] = useState<number>(1);

    /**
     * Whether the article timeline is busy or not.
     */
    const [isArticleTimelineBusy, setIsArticleTimelineBusy] = useState<boolean>(false);

    /**
     * Whether the events timeline is busy or not.
     */
    const [isEventsTimelineBusy, setIsEventsTimelineBusy] = useState<boolean>(false);

    /**
     * Whether the panel to display articles is open or not.
     */
    const [isArticlePanelOpen, setIsArticlePanelOpen] = useState<boolean>(false);

    /**
     * Store action to update the current occured error message.
     */
    const updateCurrentErrorMessage = useStoreActions((actions) => actions.GlobalDataModel.updateCurrentErrorMessage);

    /**
     * State to keep track of the current inner width and inner height of the window.
     */
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    /**
     * Set the window size state after the window has resized.
     */
    useEffect(() => {
        function handleResize() {
            setWindowSize({ width: window.innerWidth, height: window.innerHeight });
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    /**
     * Fetches the current selected page of articles.
     */
    useEffect(() => {
        const fetchArticles = async () => {
            try {
                setIsArticleTimelineBusy(true);
                const requestBody = {
                    itemsPerPage: 25,
                    pageToDeliver: currentPage,
                    tagsToInclude: [
                        {
                            name: 'Am Start',
                            id: 4,
                        },
                    ],
                };
                const result = await fetchApi('Article/PagedByTags', 'POST', requestBody);
                setAvailablePages(result.pagesAvailable);
                setArticles(result.data);
            } catch (error) {
                console.error(error);
                updateCurrentErrorMessage('Beim holen der Artikel ist ein Fehler aufgetreten!');
            } finally {
                setIsArticleTimelineBusy(false);
            }
        };
        fetchArticles();
    }, [currentPage, setCurrentPage, updateCurrentErrorMessage]);

    /**
     * Fetch the events of the current year.
     */
    useEffect(() => {
        const fetchEvents = async () => {
            try {
                setIsEventsTimelineBusy(true);
                const result = await fetchApi(`Event/GetByAllYear?year=${currentYear}`, 'GET');
                setEvents(result);
            } catch (error) {
                console.error(error);
                updateCurrentErrorMessage('Beim holen der Artikel ist ein Fehler aufgetreten!');
            } finally {
                setIsEventsTimelineBusy(false);
            }
        };
        fetchEvents();
    }, [updateCurrentErrorMessage, currentYear]);

    /**
     * The memorized data to render in the table.
     */
    const memorizedArticles = useMemo(() => articles, [articles]);

    return (
        <AtStartContainer>
            <HeadlineWrapper backgroundColor={theme.palette.white}>Kurbelixe am Start</HeadlineWrapper>
            <ContentContainer>
                <EventTimelineWrapper backgroundColor={theme.palette.white}>
                    <BusyIndicator isBusy={isEventsTimelineBusy} />
                    <EventTimeline events={events} year={currentYear} />
                </EventTimelineWrapper>
                <ArticleTimelineWrapper backgroundColor={theme.palette.white}>
                    {windowSize.width > 1400 && windowSize.width > windowSize.height ? (
                        <>
                            <BusyIndicator isBusy={isArticleTimelineBusy} />
                            <ArticleTimeline
                                articles={memorizedArticles}
                                currentPage={currentPage}
                                availablePages={availablePages}
                                navigateBackward={() => setCurrentPage(currentPage - 1)}
                                navigateForward={() => setCurrentPage(currentPage + 1)}
                                navigateToEnd={() => setCurrentPage(availablePages)}
                                navigateToStart={() => setCurrentPage(1)}
                            />
                        </>
                    ) : (
                        <>
                            <ArticleTimelinePanel
                                headerText="Am Start Artikel"
                                isOpen={isArticlePanelOpen}
                                articles={memorizedArticles}
                                closePanel={() => setIsArticlePanelOpen(false)}
                                currentPage={currentPage}
                                availablePages={availablePages}
                                isBusy={isArticleTimelineBusy}
                                navigateBackward={() => setCurrentPage(currentPage - 1)}
                                navigateForward={() => setCurrentPage(currentPage + 1)}
                                navigateToEnd={() => setCurrentPage(availablePages)}
                                navigateToStart={() => setCurrentPage(1)}
                            />
                            <OpenArticleTimelineText onClick={() => setIsArticlePanelOpen(true)}>Artikel</OpenArticleTimelineText>
                        </>
                    )}
                </ArticleTimelineWrapper>
            </ContentContainer>
        </AtStartContainer>
    );
};
