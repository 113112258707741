import {
  DefaultButton,
  getTheme,
  IButtonStyles,
  Panel,
  PanelType,
} from "@fluentui/react";
import React, { useState, useEffect, FunctionComponent } from "react";
import styled from "styled-components";
import { fetchApi } from "../helper/ApiHelper";
import { NavMenu } from "../menus/NavMenu";
import { TopMenu } from "../menus/TopMenu";
import { BusyIndicator } from "../common/BusyIndicator";
import { IBackgroundColor, IAbsolutePosition, IFontColor } from "ui.shared";
import { useStoreState, useStoreActions } from "../store/Store";
import HeaderBackground from "../assets/header_background.jpeg";
import { IAuthor } from "ui.shared";
import FacebookLogo from "../assets/facebook_logo.png";
import StravaLogo from "../assets/strava_logo.png";
import TwitterLogo from "../assets/twitter_logo.png";
import YoutubeLogo from "../assets/youtube_logo.png";
import InstagramLogo from "../assets/instagram_logo.jpg";
import parse from "html-react-parser";
import * as DOMPurify from "dompurify";
import { openUrlInNewTab } from "../helper/UrlHelper";
import { BottomNavMenu } from "../menus/BottomNavMenu";
import { TagDisplay } from "../common/TagDisplay";

const BasicLayoutContainer = styled.div<IBackgroundColor>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${(props) => props.backgroundColor};
`;

const HeaderContainer = styled.div<IBackgroundColor>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 23%;
  min-height: 70px;
  background-image: url(${HeaderBackground});
  background-size: cover;
  background-position: center 70%;
  background-color: ${(props) => props.backgroundColor};
  @media only screen and (max-width: 790px) {
    justify-content: center;
    flex-direction: column;
  }
  @media only screen and (max-height: 800px) {
    justify-content: center;
    flex-direction: column;
  }
`;

const HeadlineWrapper = styled.div<IFontColor>`
  margin-top: auto;
  margin-bottom: auto;
  font-weight: bold;
  font-size: 44px;
  color: ${(props) => props.fontColor};
  text-shadow: 3px 5px 4px rgba(0, 0, 0, 0.9);
`;

const LeftNavWrapper = styled.div<IBackgroundColor>`
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: ${(props) => props.backgroundColor};
  @media (orientation: portrait) {
    display: none;
  }
`;

const ProfileWrapper = styled.div<IBackgroundColor>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 100px;
  height: 165px;
  width: 165px;
  border-radius: 50%;
  background-color: ${(props) => props.backgroundColor};
  &:hover {
    transform: scale(1.05);
  }
  @media only screen and (max-width: 1100px) {
    margin: 0 50px;
  }
  @media only screen and (max-width: 865px) {
    margin: 0 25px;
  }
  @media only screen and (max-width: 790px) {
    display: none;
  }
  @media only screen and (max-height: 800px) {
    display: none;
  }
`;

interface IProfilePhoto {
  /**
   * The absolute url to the profile photo.
   */
  photoUrl: string;
}

const ProfilePhoto = styled.div<IAbsolutePosition & IProfilePhoto>`
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-image: url(${(props) => props.photoUrl});
  background-position: center center;
`;

const LayoutContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  @media (orientation: portrait) {
    flex-direction: column;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  overflow-y: auto;
  overflow-x: auto;
`;

const PanelBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 10px 40px;
`;

const AuthorPanelProfilePhotoWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const AuthorPanelDisplayNameWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  margin-top: 15px;
`;

const AuthorPanelIntroductionTextWrapper = styled.div`
  margin-top: 35px;
  white-space: pre-wrap;
`;

const PanelFooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
`;

const SocialMediaIcon = styled.img`
  height: 25px;
  width: auto;
  margin-right: 20px;
  cursor: pointer;
`;

const SmallDisplayProfileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 80%;
`;

const TagDisplayWrapper = styled.div`
  min-width: 100px;
  cursor: pointer;
`;

/**
 * Interface for the properties of the basic layout.
 */
interface IBasicLayout {
  /**
   * The children that will be rendered inside this layout.
   */
  children: any;
}

/**
 * The basic layout component.
 */
export const BasicLayout: FunctionComponent<IBasicLayout> = (props) => {
  const theme = getTheme();

  /**
   * State to keep track of the current inner width and inner height of the window.
   */
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  /**
   * Store state of the currently available authors.
   */
  const authors = useStoreState((state) => state.GlobalDataModel.authors);

  /**
   * Store action to update the currently available authors.
   */
  const updateAuthors = useStoreActions(
    (actions) => actions.GlobalDataModel.updateAuthors
  );

  /**
   * State of the currently selected author.
   */
  const [selectedAuthor, setSelectedAuthor] = useState<IAuthor | undefined>(
    undefined
  );

  /**
   * Whether the panel that introduce the selected author is open or not.
   */
  const [isAuthorPanelOpen, setIsAuthorPanelOpen] = useState<boolean>(false);

  /**
   * The state of the type of the author panel.
   */
  const [authorPanelType, setAuthorPanelType] = useState<PanelType>();

  /**
   * Whether the basic layout is busy or not.
   */
  const [isBusy, setIsBusy] = useState<boolean>(false);

  /**
   * Store action to update the current occured error message.
   */
  const updateCurrentErrorMessage = useStoreActions(
    (actions) => actions.GlobalDataModel.updateCurrentErrorMessage
  );

  /**
   * Fetch the list of authors.
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsBusy(true);
        const result = await fetchApi("Author/All");
        updateAuthors(result);
      } catch (error) {
        console.error(error);
        updateCurrentErrorMessage(
          "Beim holen der Autoren ist ein Fehler aufgetreten!"
        );
      } finally {
        setIsBusy(false);
      }
    };
    fetchData();
  }, [updateAuthors, updateCurrentErrorMessage]);

  /**
   * Set the window size state after the window has resized.
   */
  useEffect(() => {
    function handleResize() {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  /**
   * Styles of the button to close the author panel.
   */
  const closeAuthorPanelButtonStyles: IButtonStyles = {
    root: {
      marginLeft: "auto",
    },
  };

  /**
   * Footer of the article timeline panel.
   */
  const onRenderFooterContent = () => (
    <PanelFooter>
      {selectedAuthor && (
        <div>
          {selectedAuthor.facebookProfile && (
            <SocialMediaIcon
              src={FacebookLogo}
              alt="Fehler"
              onClick={() => openUrlInNewTab(selectedAuthor.facebookProfile)}
            />
          )}
          {selectedAuthor.instagramProfile && (
            <SocialMediaIcon
              src={InstagramLogo}
              alt="Fehler"
              onClick={() => openUrlInNewTab(selectedAuthor.instagramProfile)}
            />
          )}
          {selectedAuthor.stravaProfile && (
            <SocialMediaIcon
              src={StravaLogo}
              alt="Fehler"
              onClick={() => openUrlInNewTab(selectedAuthor.stravaProfile)}
            />
          )}
          {selectedAuthor.twitterProfile && (
            <SocialMediaIcon
              src={TwitterLogo}
              alt="Fehler"
              onClick={() => openUrlInNewTab(selectedAuthor.twitterProfile)}
            />
          )}
          {selectedAuthor.youTubeChannel && (
            <SocialMediaIcon
              src={YoutubeLogo}
              alt="Fehler"
              onClick={() => openUrlInNewTab(selectedAuthor.youTubeChannel)}
            />
          )}
        </div>
      )}
      <DefaultButton
        styles={closeAuthorPanelButtonStyles}
        text="Schließen"
        onClick={closeAuthorPanel}
      />
    </PanelFooter>
  );
  const onRenderBodyContent = () => {
    if (!selectedAuthor) {
      return <div />;
    } else {
      const cleanHtml = DOMPurify.sanitize(selectedAuthor.profileText);
      return (
        <PanelBody>
          {selectedAuthor && selectedAuthor.pictures && (
            <AuthorPanelProfilePhotoWrapper>
              <ProfilePhoto
                photoUrl={
                  selectedAuthor && selectedAuthor.pictures[0].pictureUrl
                }
              />
            </AuthorPanelProfilePhotoWrapper>
          )}
          <AuthorPanelDisplayNameWrapper>
            {selectedAuthor.displayName}
          </AuthorPanelDisplayNameWrapper>
          <AuthorPanelIntroductionTextWrapper>
            {parse(cleanHtml)}
          </AuthorPanelIntroductionTextWrapper>
        </PanelBody>
      );
    }
  };

  /**
   * Close the author panel.
   */
  const closeAuthorPanel = () => {
    setSelectedAuthor(undefined);
    setIsAuthorPanelOpen(false);
  };

  /**
   * Opens the detail panelto display author informations.
   */
  const openAuthorDetailsPanel = (author: IAuthor, isPanelNear: boolean) => {
    setSelectedAuthor(author);
    if (isPanelNear) {
      setAuthorPanelType(PanelType.customNear);
    } else {
      setAuthorPanelType(PanelType.custom);
    }

    setIsAuthorPanelOpen(true);
  };

  return (
    <BasicLayoutContainer backgroundColor={theme.palette.neutralLighter}>
      <BusyIndicator isBusy={isBusy} />
      <Panel
        type={authorPanelType}
        customWidth={"650px"}
        isOpen={isAuthorPanelOpen}
        isLightDismiss={true}
        onDismiss={closeAuthorPanel}
        closeButtonAriaLabel="Schließen"
        onRenderFooterContent={onRenderFooterContent}
        onRenderBody={onRenderBodyContent}
        isFooterAtBottom={true}
      />
      <HeaderContainer backgroundColor={theme.palette.neutralDark}>
        {windowSize.width >= 790 &&
          windowSize.height >= 800 &&
          authors &&
          authors[0] &&
          authors[0].pictures &&
          authors[0].pictures.length > 0 && (
            <ProfileWrapper
              backgroundColor={theme.palette.white}
              onClick={() => openAuthorDetailsPanel(authors[0], true)}
            >
              <ProfilePhoto photoUrl={authors[0].pictures[0].pictureUrl} />
            </ProfileWrapper>
          )}
        <HeadlineWrapper fontColor={theme.palette.white}>
          HarzerKurbelixe
        </HeadlineWrapper>
        {windowSize.width >= 790 &&
        windowSize.height >= 800 &&
        authors &&
        authors[1] &&
        authors[1].pictures &&
        authors[1].pictures.length > 0 ? (
          <ProfileWrapper
            backgroundColor={theme.palette.white}
            onClick={() => openAuthorDetailsPanel(authors[1], false)}
          >
            <ProfilePhoto
              photoUrl={
                authors[1].pictures && authors[1].pictures[0].pictureUrl
              }
            />
          </ProfileWrapper>
        ) : (
          authors &&
          authors[0] &&
          authors[1] &&
          authors[0].displayName &&
          authors[1].displayName && (
            <SmallDisplayProfileContainer>
              <TagDisplayWrapper
                onClick={() => openAuthorDetailsPanel(authors[0], true)}
              >
                <TagDisplay text={authors[0].displayName} />
              </TagDisplayWrapper>
              <TagDisplayWrapper
                onClick={() => openAuthorDetailsPanel(authors[1], false)}
              >
                <TagDisplay text={authors[1].displayName} />
              </TagDisplayWrapper>
            </SmallDisplayProfileContainer>
          )
        )}
      </HeaderContainer>
      <LayoutContainer>
        <LeftNavWrapper backgroundColor={theme.palette.neutralLighterAlt}>
          <NavMenu navMenuWidth={245} navHeight="70vh" />
          <BottomNavMenu backgroundColor={theme.palette.neutralLighterAlt} />
        </LeftNavWrapper>
        <TopMenu
          navMenuWidth={windowSize.width}
          backgroundColor={theme.palette.neutralLighterAlt}
        />
        <ContentContainer>{props.children}</ContentContainer>
        {windowSize.height > windowSize.width && (
          <BottomNavMenu backgroundColor={theme.palette.neutralLighterAlt} />
        )}
      </LayoutContainer>
    </BasicLayoutContainer>
  );
};
