/**
 * Open a url in a new browser tab and focus it.
 * @param url The url to open.
 */
export const openUrlInNewTab = (url: string | undefined) => {
    if (url) {
        const newWindow = window.open(url, '_blank');
        newWindow?.focus();
    }
};
