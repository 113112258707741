import React, {
  useState,
  FunctionComponent,
  useEffect,
  useCallback,
} from "react";
import styled from "styled-components";
import { IconButton, IIconProps } from "@fluentui/react";
import { NavMenu } from "./NavMenu";
import { IBackgroundColor } from "ui.shared";

const TopMenuContainer = styled.div<IBackgroundColor>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
  z-index: 10;
  background-color: ${(props) => props.backgroundColor};
  @media (orientation: landscape) {
    display: none;
  }
`;

const NavContainer = styled.div<IBackgroundColor>`
  position: absolute;
  top: 30px;
  background-color: ${(props) => props.backgroundColor};
`;

/**
 * Interface for the properties of the top menu.
 */
interface ITopMenu {
  /**
   * The width of the navigation menu.
   */
  navMenuWidth: number;

  /**
   * Hex code of the background color.
   */
  backgroundColor: string;
}

/**
 * The top side menu wrapper component.
 */
export const TopMenu: FunctionComponent<ITopMenu> = (props) => {
  /**
   * State to toggle the visibility of the top navigation menu.
   */
  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);

  /**
   * Callback for the click event listener.
   */
  const clickListener = useCallback(() => {
    if (isNavMenuOpen) {
      setIsNavMenuOpen(false);
    }
  }, [isNavMenuOpen]);

  /**
   * Add an event listener to close the menu.
   */
  useEffect(() => {
    // Attach the listeners on component mount.
    document.addEventListener("click", clickListener);
    // Detach the listeners on component unmount.
    return () => {
      document.removeEventListener("click", clickListener);
    };
  }, [clickListener]);

  /**
   * Props of the icon used in the button to open the menu.
   */
  const menuIconProps: IIconProps = {
    iconName: "CollapseMenu",
  };

  return (
    <TopMenuContainer backgroundColor={props.backgroundColor}>
      <IconButton
        iconProps={menuIconProps}
        onClick={() => setIsNavMenuOpen(!isNavMenuOpen)}
      />
      <NavContainer backgroundColor={props.backgroundColor}>
        {isNavMenuOpen && (
          <NavMenu navMenuWidth={props.navMenuWidth} navHeight="50vh" />
        )}
      </NavContainer>
    </TopMenuContainer>
  );
};
