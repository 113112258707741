import { Action, action } from 'easy-peasy';

/**
 * Interface for the navigation model.
 */
export interface INavigationModel {
    /**
     * The current selected navigation key.
     */
    selectedNavKey: string;
    /**
     * Action to update the current selected navigation key.
     */
    updateSelectedNavKey: Action<INavigationModel, string>;
}

/**
 * Initial state of the navigation model.
 */
export const NavigationModel: INavigationModel = {
    selectedNavKey: '/',
    updateSelectedNavKey: action((state, payload) => {
        state.selectedNavKey = payload;
    }),
};
