import { getTheme } from '@fluentui/react';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { IBackgroundColor } from 'ui.shared/lib/types/StyledComponentsTypes';
import { IAuthor } from 'ui.shared/lib/types/IAuthor';
import { TagDisplay } from '../common/TagDisplay';
import { IEvent } from 'ui.shared/lib/types/IEvent';
import { formatDateWithoutTime } from 'ui.shared/lib/helper/DateFormatHelper';
import { openUrlInNewTab } from '../helper/UrlHelper';

const EventTimelineWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
`;

const EventTimelineContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 10px;
    overflow-y: auto;
    min-width: max-content;
`;

const EventTimelineElementContainer = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    min-width: max-content;
    min-height: max-content;
`;

const FlexRow = styled.div`
    display: flex;
`;

const TimelineBubble = styled.div<IBackgroundColor>`
    height: 25px;
    width: 25px;
    border-radius: 50%;
    margin: 10px 0;
    background-color: ${(props) => props.backgroundColor};
    ${EventTimelineElementContainer}:hover & {
        transform: scale(1.25);
    }
`;

const TimelineLineWrapper = styled.div`
    display: flex;
    width: 25px;
    justify-content: center;
    align-items: center;
`;

const TimelineLine = styled.div<IBackgroundColor>`
    display: flex;
    width: 5px;
    min-height: 100px;
    border-radius: 4px;
    background-color: ${(props) => props.backgroundColor};
`;

const TimelineDate = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    padding: 0 15px;
`;

const TimelineEventName = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`;

const TimelineEventParticipantsHeadline = styled.div`
    margin-bottom: 15px;
`;

const TimelineEventParticipantsContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 15px;
`;

const TagDisplayWrapper = styled.div`
    margin-right: 20px;
`;

const YearDisplay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    padding: 15px;
`;

/**
 * Interface for the props of the event timeline.
 */
interface IEventTimeline {
    /**
     * The events to display in the timeline.
     */
    events: IEvent[];

    /**
     * The year of the fetched events.
     */
    year?: number;
}

/**
 * Timeline component to show events.
 */
export const EventTimeline: FunctionComponent<IEventTimeline> = (props) => {
    const theme = getTheme();

    return (
        <EventTimelineWrapper>
            {props.year && <YearDisplay>Events im Jahr {props.year}</YearDisplay>}
            <EventTimelineContainer>
                {props.events &&
                    props.events.map((event: IEvent, i: number) => (
                        <EventTimelineElementContainer key={i} onClick={() => openUrlInNewTab(event.link)}>
                            <FlexRow>
                                <TimelineBubble backgroundColor={theme.palette.themePrimary} />
                                <TimelineDate>{formatDateWithoutTime(event.date)}</TimelineDate>
                                <TimelineEventName>{event.name}</TimelineEventName>
                            </FlexRow>
                            <FlexRow>
                                <TimelineLineWrapper>
                                    <TimelineLine backgroundColor={theme.palette.themePrimary} />
                                </TimelineLineWrapper>
                                <TimelineEventParticipantsContainer>
                                    <TimelineEventParticipantsHeadline>Teilnehmen wird:</TimelineEventParticipantsHeadline>
                                    <FlexRow>
                                        {event.authors &&
                                            event.authors.map((author: IAuthor, i: number) => (
                                                <TagDisplayWrapper key={i}>
                                                    <TagDisplay text={author.displayName} />
                                                </TagDisplayWrapper>
                                            ))}
                                    </FlexRow>
                                </TimelineEventParticipantsContainer>
                            </FlexRow>
                        </EventTimelineElementContainer>
                    ))}
            </EventTimelineContainer>
        </EventTimelineWrapper>
    );
};
