import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { fetchApi } from '../helper/ApiHelper';
import { IArticle } from 'ui.shared';
import { ArticleTimeline } from '../common/ArticleTimeline';
import { IBackgroundColor, IBorderColor } from 'ui.shared';
import { getTheme } from '@fluentui/react';
import { useStoreState, useStoreActions } from '../store/Store';
import { IAuthor } from 'ui.shared';
import { BusyIndicator } from '../common/BusyIndicator';
import HarzerKurbelixeLogo from '../assets/HarzerKurbelixe.svg';

const HomeContainer = styled.div`
    display: flex;
    flex: 1;
    padding: 20px;
    @media only screen and (max-width: 820px) {
        flex-direction: column;
        min-height: max-content;
    }
    @media only screen and (max-width: 650px) {
        padding: 20px;
    }
    @media only screen and (max-width: 440px) {
        padding: 10px;
    }
    @media only screen and (max-width: 415px) {
        padding: 0;
    }
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 100%;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    margin-left: 15px;
    @media only screen and (max-width: 820px) {
        overflow: unset;
        width: 100%;
        margin: 15px 0 0 0;
        max-height: unset;
        height: unset;
        flex-direction: column-reverse;
    }
`;

const StravaFeedContainer = styled.div<IBackgroundColor>`
    display: flex;
    flex: 2;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 1px 1px rgba(16, 27, 30, 0.15), 0 2px 2px rgba(16, 27, 30, 0.15), 0 4px 4px rgba(16, 27, 30, 0.15), 0 8px 8px rgba(16, 27, 30, 0.15),
        0 16px 16px rgba(16, 27, 30, 0.15);
    border-radius: 4px;
    overflow: auto;
    background-color: ${(props) => props.backgroundColor};
    @media only screen and (max-width: 820px) {
        overflow: unset;
        width: 100%;
        min-height: max-content;
    }
`;

const StravaActivitiesFrame = styled.iframe<IBorderColor>`
    padding: 10px 0;
    margin: 0 10px;
    min-height: 160px;
    border-bottom: 2px solid ${(props) => props.borderColor};
`;

const StravaFeedHeadline = styled.div`
    font-weight: bold;
    font-size: 18px;
    padding: 15px;
`;

const WelcomeTextWrapper = styled.div<IBackgroundColor>`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    padding: 15px;
    box-shadow: 0 1px 1px rgba(16, 27, 30, 0.15), 0 2px 2px rgba(16, 27, 30, 0.15), 0 4px 4px rgba(16, 27, 30, 0.15), 0 8px 8px rgba(16, 27, 30, 0.15),
        0 16px 16px rgba(16, 27, 30, 0.15);
    border-radius: 4px;
    text-align: center;
    background-color: ${(props) => props.backgroundColor};
`;

const TimelineWrapper = styled.div<IBackgroundColor>`
    display: flex;
    flex: 1;
    position: relative;
    overflow: hidden;
    box-shadow: 0 1px 1px rgba(16, 27, 30, 0.15), 0 2px 2px rgba(16, 27, 30, 0.15), 0 4px 4px rgba(16, 27, 30, 0.15), 0 8px 8px rgba(16, 27, 30, 0.15),
        0 16px 16px rgba(16, 27, 30, 0.15);
    border-radius: 4px;
    background-color: ${(props) => props.backgroundColor};
    @media only screen and (max-width: 820px) {
        min-height: max-content;
    }
`;

const HarzerKurbelixeLogoWrapper = styled.div<IBackgroundColor>`
    display: flex;
    flex: 1;
    margin-top: 20px;
    padding: 10px;
    box-shadow: 0 1px 1px rgba(16, 27, 30, 0.15), 0 2px 2px rgba(16, 27, 30, 0.15), 0 4px 4px rgba(16, 27, 30, 0.15), 0 8px 8px rgba(16, 27, 30, 0.15),
        0 16px 16px rgba(16, 27, 30, 0.15);
    border-radius: 4px;
    background-color: ${(props) => props.backgroundColor};
    @media only screen and (max-width: 820px) {
        min-height: 300px;
        margin-top: unset;
        margin-bottom: 20px;
    }
`;

const HarzerKurbelixeLogoContainer = styled.div`
    display: flex;
    flex: 1;
    background-image: url(${HarzerKurbelixeLogo});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`;

/**
 * View component for the home view.
 */
export const HomeView: FunctionComponent = () => {
    const theme = getTheme();

    /**
     * State of the fetched articles.
     */
    const [articles, setArticles] = useState<IArticle[]>([]);

    /**
     * State of the current fetched page of the articles table.
     */
    const [currentPage, setCurrentPage] = useState<number>(1);

    /**
     * State of the maximum available pages of the articles table.
     */
    const [availablePages, setAvailablePages] = useState<number>(1);

    /**
     * Store state of the currently available authors.
     */
    const authors = useStoreState((state) => state.GlobalDataModel.authors);

    /**
     * Whether the article timeline is busy or not.
     */
    const [isArticleTimelineBusy, setIsArticleTimelineBusy] = useState<boolean>(false);

    /**
     * Store action to update the current occured error message.
     */
    const updateCurrentErrorMessage = useStoreActions((actions) => actions.GlobalDataModel.updateCurrentErrorMessage);

    /**
     * Fetches the current selected page of articles.
     */
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsArticleTimelineBusy(true);
                const requestBody = {
                    itemsPerPage: 25,
                    pageToDeliver: currentPage,
                };
                const result = await fetchApi('Article/Paged', 'POST', requestBody);
                setAvailablePages(result.pagesAvailable);
                setArticles(result.data);
            } catch (error) {
                console.error(error);
                updateCurrentErrorMessage('Beim holen der Artikel ist ein Fehler aufgetreten!');
            } finally {
                setIsArticleTimelineBusy(false);
            }
        };
        fetchData();
    }, [currentPage, setCurrentPage, updateCurrentErrorMessage]);

    /**
     * The memorized data to render in the table.
     */
    const memorizedArticles = useMemo(() => articles, [articles]);

    return (
        <HomeContainer>
            <ContentContainer>
                <WelcomeTextWrapper backgroundColor={theme.palette.white}>HarzerKurbelixe - wir drehen am Rad - bewegende Erlebnisse in Natur und Gemeinschaft</WelcomeTextWrapper>
                <TimelineWrapper backgroundColor={theme.palette.white}>
                    <BusyIndicator isBusy={isArticleTimelineBusy} />
                    <ArticleTimeline
                        articles={memorizedArticles}
                        currentPage={currentPage}
                        availablePages={availablePages}
                        navigateBackward={() => setCurrentPage(currentPage - 1)}
                        navigateForward={() => setCurrentPage(currentPage + 1)}
                        navigateToEnd={() => setCurrentPage(availablePages)}
                        navigateToStart={() => setCurrentPage(1)}
                    />
                </TimelineWrapper>
            </ContentContainer>
            <Wrapper>
                <StravaFeedContainer backgroundColor={theme.palette.white}>
                    <StravaFeedHeadline>Strava Feed</StravaFeedHeadline>
                    {authors &&
                        authors.length > 0 &&
                        authors.map((author: IAuthor, i: number) => {
                            if (author.stravaActivitySummary) {
                                return (
                                    <StravaActivitiesFrame
                                        key={i}
                                        borderColor={theme.palette.neutralLighterAlt}
                                        height="160"
                                        width={'90%'}
                                        frameBorder="0"
                                        scrolling="no"
                                        src={author.stravaActivitySummary}
                                    />
                                );
                            }
                            return null;
                        })}
                </StravaFeedContainer>
                <HarzerKurbelixeLogoWrapper backgroundColor={theme.palette.white}>
                    <HarzerKurbelixeLogoContainer />
                </HarzerKurbelixeLogoWrapper>
            </Wrapper>
        </HomeContainer>
    );
};
