import { getTheme, IColumn, TooltipHost } from '@fluentui/react';
import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IBackgroundColor } from 'ui.shared/lib/types/StyledComponentsTypes';
import { IExternalBlog } from 'ui.shared/lib/types/IExternalBlog';
import { IExternalArticle } from 'ui.shared/lib/types/IExternalArticle';
import { useStoreActions } from '../store/Store';
import { fetchApi } from '../helper/ApiHelper';
import { Table } from '../common/Table';
import { formatDateWithoutTime } from 'ui.shared/lib/helper/DateFormatHelper';
import { UrlButton } from '../common/UrlButton';

const ReadingMaterialContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 10px 0 10px;
`;

const HeadlineWrapper = styled.div<IBackgroundColor>`
    font-size: 20px;
    font-weight: bold;
    margin: 10px 10px 20px 10px;
    padding: 15px;
    min-height: max-content;
    box-shadow: 0 1px 1px rgba(16, 27, 30, 0.15), 0 2px 2px rgba(16, 27, 30, 0.15), 0 4px 4px rgba(16, 27, 30, 0.15), 0 8px 8px rgba(16, 27, 30, 0.15),
        0 16px 16px rgba(16, 27, 30, 0.15);
    border-radius: 4px;
    text-align: center;
    background-color: ${(props) => props.backgroundColor};
`;

const TextHeadline = styled.div<IBackgroundColor>`
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    padding: 15px;
    margin: 10px;
    box-shadow: 0 1px 1px rgba(16, 27, 30, 0.15), 0 2px 2px rgba(16, 27, 30, 0.15), 0 4px 4px rgba(16, 27, 30, 0.15), 0 8px 8px rgba(16, 27, 30, 0.15),
        0 16px 16px rgba(16, 27, 30, 0.15);
    border-radius: 4px;
    background-color: ${(props) => props.backgroundColor};
`;

const SiteWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const TableWrapper = styled.div`
    display: flex;
    flex: 1;
    padding: 10px 10px 20px 10px;
    overflow: hidden;
    min-height: 350px;
`;

const CardsContainer = styled.div`
    display: flex;
    flex: 1;
    @media only screen and (max-width: 1024px) {
        flex-direction: column;
        min-height: max-content;
    }
`;

/**
 * View component for the reading material view.
 */
export const ReadingMaterialView: FunctionComponent = () => {
    const theme = getTheme();

    /**
     * State of the fetched external blogs.
     */
    const [externalBlogs, setExternalBlogs] = useState<IExternalBlog[]>([]);

    /**
     * State of the fetched external blogs.
     */
    const [externalArticles, setExternalArticles] = useState<IExternalArticle[]>([]);

    /**
     * Whether the component that displays the external blogs is busy or not.
     */
    const [isExternalBlogContainerBusy, setIsExternalBlogContainerBusy] = useState<boolean>(false);

    /**
     * Whether the component that displays the external articles is busy or not.
     */
    const [isExternalArticlesContainerBusy, setIsExternalArticlesContainerBusy] = useState<boolean>(false);

    /**
     * Store action to update the current occured error message.
     */
    const updateCurrentErrorMessage = useStoreActions((actions) => actions.GlobalDataModel.updateCurrentErrorMessage);

    /**
     * The maximum of available external blog pages.
     */
    const [availableBlogPages, setAvailableBlogPages] = useState<number>(1);

    /**
     * The maximum of available external blog pages.
     */
    const [currentBlogPage, setCurrentBlogPage] = useState<number>(1);

    /**
     * The maximum of available external article pages.
     */
    const [availableArticlePages, setAvailableArticlePages] = useState<number>(1);

    /**
     * The maximum of available external blog pages.
     */
    const [currentArticlePage, setCurrentArticlePage] = useState<number>(1);

    /**
     * Fetching the external blogs.
     */
    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                setIsExternalBlogContainerBusy(true);
                const requestBody = {
                    itemsPerPage: 50,
                    pageToDeliver: currentBlogPage,
                };
                const result = await fetchApi('ExternalBlog/Paged', 'POST', requestBody);
                setAvailableBlogPages(result.pagesAvailable);
                setExternalBlogs(result.data);
            } catch (error) {
                console.error(error);
                updateCurrentErrorMessage('Beim holen der Blogs ist ein Fehler aufgetreten!');
            } finally {
                setIsExternalBlogContainerBusy(false);
            }
        };
        fetchBlogs();
    }, [currentBlogPage, setCurrentBlogPage, updateCurrentErrorMessage]);

    /**
     * Fetching the external articles.
     */
    useEffect(() => {
        const fetchArticles = async () => {
            try {
                setIsExternalArticlesContainerBusy(true);
                const requestBody = {
                    itemsPerPage: 50,
                    pageToDeliver: currentArticlePage,
                };
                const result = await fetchApi('ExternalArticle/Paged', 'POST', requestBody);
                setAvailableArticlePages(result.pagesAvailable);
                setExternalArticles(result.data);
            } catch (error) {
                console.error(error);
                updateCurrentErrorMessage('Beim holen der Artikel ist ein Fehler aufgetreten!');
            } finally {
                setIsExternalArticlesContainerBusy(false);
            }
        };
        fetchArticles();
    }, [currentArticlePage, setCurrentArticlePage, updateCurrentErrorMessage]);

    /**
     * Columns of the external blogs table.
     */
    const externalBlogsColumns: IColumn[] = [
        {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 200,
            maxWidth: 1000,
            isResizable: true,
            onRender: (item: IExternalBlog) => <TooltipHost content={item.name}>{item.name}</TooltipHost>,
        },
        {
            key: 'link',
            name: 'Link',
            fieldName: 'link',
            minWidth: 75,
            maxWidth: 75,
            isResizable: true,
            onRender: (item: IExternalBlog) => <UrlButton url={item.link} />,
        },
    ];

    /**
     * Columns of the external articles table.
     */
    const externalArticlesColumns: IColumn[] = [
        {
            key: 'creationTime',
            name: 'Datum',
            fieldName: 'creationTime',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            onRender: (item: IExternalArticle) => {
                const formattedDate = formatDateWithoutTime(item.creationTime);
                return <TooltipHost content={formattedDate}>{formattedDate}</TooltipHost>;
            },
        },
        {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 400,
            maxWidth: 1000,
            isResizable: true,
            onRender: (item: IExternalArticle) => <TooltipHost content={item.name}>{item.name}</TooltipHost>,
        },
        {
            key: 'link',
            name: 'Link',
            fieldName: 'link',
            minWidth: 75,
            maxWidth: 75,
            isResizable: true,
            onRender: (item: IExternalArticle) => <UrlButton url={item.link} />,
        },
    ];

    return (
        <ReadingMaterialContainer>
            <HeadlineWrapper backgroundColor={theme.palette.white}>Lesestoff</HeadlineWrapper>
            <CardsContainer>
                <SiteWrapper>
                    <TextHeadline backgroundColor={theme.palette.white}>Blogs die wir mögen</TextHeadline>
                    <TableWrapper>
                        <Table
                            isBusy={isExternalBlogContainerBusy}
                            columns={externalBlogsColumns}
                            data={externalBlogs}
                            currentPage={currentBlogPage}
                            availablePages={availableBlogPages}
                            navigateBackward={() => setCurrentBlogPage(currentBlogPage - 1)}
                            navigateForward={() => setCurrentBlogPage(currentBlogPage + 1)}
                            navigateToEnd={() => setCurrentBlogPage(availableBlogPages)}
                            navigateToStart={() => setCurrentBlogPage(1)}
                        />
                    </TableWrapper>
                </SiteWrapper>
                <SiteWrapper>
                    <TextHeadline backgroundColor={theme.palette.white}>Lesenswerte Artikel</TextHeadline>
                    <TableWrapper>
                        <Table
                            isBusy={isExternalArticlesContainerBusy}
                            columns={externalArticlesColumns}
                            data={externalArticles}
                            currentPage={currentArticlePage}
                            availablePages={availableArticlePages}
                            navigateBackward={() => setCurrentArticlePage(currentArticlePage - 1)}
                            navigateForward={() => setCurrentArticlePage(currentArticlePage + 1)}
                            navigateToEnd={() => setCurrentArticlePage(availableArticlePages)}
                            navigateToStart={() => setCurrentArticlePage(1)}
                        />
                    </TableWrapper>
                </SiteWrapper>
            </CardsContainer>
        </ReadingMaterialContainer>
    );
};
