import { getTheme } from '@fluentui/react';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { IBackgroundColor } from 'ui.shared/lib/types/StyledComponentsTypes';
import { useStoreState } from '../store/Store';
import parse from 'html-react-parser';
import { returnSecureHtml } from '../helper/HtmlHelper';

const AboutUsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 10px;
`;

const HeadlineWrapper = styled.div<IBackgroundColor>`
    font-size: 20px;
    font-weight: bold;
    margin: 20px 10px;
    padding: 15px;
    box-shadow: 0 1px 1px rgba(16, 27, 30, 0.15), 0 2px 2px rgba(16, 27, 30, 0.15), 0 4px 4px rgba(16, 27, 30, 0.15), 0 8px 8px rgba(16, 27, 30, 0.15),
        0 16px 16px rgba(16, 27, 30, 0.15);
    border-radius: 4px;
    text-align: center;
    background-color: ${(props) => props.backgroundColor};
`;

const AboutHarzerKurbelixeCard = styled.div<IBackgroundColor>`
    display: flex;
    flex-direction: column;
    min-height: max-content;
    margin: 0 10px 20px 10px;
    padding: 25px;
    box-shadow: 0 1px 1px rgba(16, 27, 30, 0.15), 0 2px 2px rgba(16, 27, 30, 0.15), 0 4px 4px rgba(16, 27, 30, 0.15), 0 8px 8px rgba(16, 27, 30, 0.15),
        0 16px 16px rgba(16, 27, 30, 0.15);
    border-radius: 4px;
    background-color: ${(props) => props.backgroundColor};
`;

const TextCard = styled.div<IBackgroundColor>`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: max-content;
    margin: 0 10px 20px 10px;
    padding: 25px;
    box-shadow: 0 1px 1px rgba(16, 27, 30, 0.15), 0 2px 2px rgba(16, 27, 30, 0.15), 0 4px 4px rgba(16, 27, 30, 0.15), 0 8px 8px rgba(16, 27, 30, 0.15),
        0 16px 16px rgba(16, 27, 30, 0.15);
    border-radius: 4px;
    background-color: ${(props) => props.backgroundColor};
`;

const TextHeadline = styled.div`
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
`;

const HarzerKurbelixeTextSubHeadline = styled.div`
    display: flex;
    justify-content: center;
    font-size: 16px;
    margin-bottom: 15px;
`;

const Text = styled.div`
    text-align: left;
    word-wrap: break-word;
    margin-bottom: 15px;
`;

const AuthorCardsContainer = styled.div`
    display: flex;
    flex: 1;
    min-height: max-content;
    @media only screen and (max-width: 1024px) {
        flex-direction: column;
    }
`;

/**
 * View component for the about us view.
 */
export const AboutUsView: FunctionComponent = () => {
    const theme = getTheme();

    /**
     * Store state of the currently available authors.
     */
    const authors = useStoreState((state) => state.GlobalDataModel.authors);

    return (
        <AboutUsContainer>
            <HeadlineWrapper backgroundColor={theme.palette.white}>Über Uns</HeadlineWrapper>
            <AboutHarzerKurbelixeCard backgroundColor={theme.palette.white}>
                <TextHeadline>HarzerKurbelixe – Wir drehen am Rad</TextHeadline>
                <HarzerKurbelixeTextSubHeadline>Wer wir sind – Was uns ausmacht – Warum wir es lieben</HarzerKurbelixeTextSubHeadline>
                <Text>Hey Du, schön dass Du da bist,</Text>
                <Text>
                    komm mit auf unsere Reise. Lass dich inspirieren von bewegenden Erlebnissen in Natur und Gemeinschaft. Wir, das sind Heiko und Isabel, zeigen dir die Schönheit
                    des Harzes auch abseits der bekannten Routen. Auf unzähligen Touren haben wir unsere Heimatliebe neu entdeckt - Leben, wo andere Urlaub machen. Es ist uns daher
                    eine HARZensangelegenheit, dich für die Vielfalt unseres „heimischen Wohnzimmers“ zu begeistern – und es hat so viel zu bieten! Aber was macht eine Tour
                    eigentlich zu einem besonderen Erlebnis? Ist es NUR die Landschaft, das Wetter oder die sportliche Herausforderung? Gemeinsam starten, gemeinsam kämpfen,
                    gemeinsam lachen und fluchen (auch das kommt mal vor) – sind all die Dinge, die uns antreiben. Nicht auf der Suche nach Rekorden, sondern auf der Suche nach
                    Erholung und dem Schlüssel zum Glück. Über den Tag hinweg befreien wir uns von den Lasten des Alltags, tanken neue Kraft und lassen neue verrückte Ideen
                    entstehen… Dieses Erfolgsrezept gibt uns die mentale Stärke auch körperlich über uns hinauszuwachsen. Kein Weg ist zu weit - kein Berg ist zu hoch.
                </Text>
                <Text>Du möchtest mehr erfahren von unseren verrückten Ideen? Dann sei gespannt und begleite uns auf unserer gemeinsamen Reise…</Text>
                <div>HARZliche Grüße</div>
                <div>Deine HarzerKurbelixe – wir drehen für dich am Rad</div>
            </AboutHarzerKurbelixeCard>
            <AuthorCardsContainer>
                {authors && authors[0] && (
                    <TextCard backgroundColor={theme.palette.white}>
                        <TextHeadline>{authors[0].displayName}</TextHeadline>
                        {authors[0].introductionText && <Text>{parse(returnSecureHtml(authors[0].introductionText))}</Text>}
                    </TextCard>
                )}
                {authors && authors[1] && (
                    <TextCard backgroundColor={theme.palette.white}>
                        <TextHeadline>{authors[1].displayName}</TextHeadline>
                        {authors[1].introductionText && <Text>{parse(returnSecureHtml(authors[1].introductionText))}</Text>}
                    </TextCard>
                )}
            </AuthorCardsContainer>
        </AboutUsContainer>
    );
};
