import { createStore, createTypedHooks } from 'easy-peasy';
import { NavigationModel, INavigationModel } from './model/NavigationModel';
import { GlobalDataModel, IGlobalDataModel } from './model/GlobalDataModel';

/**
 * Interface for the store model.
 */
interface IStoreModel {
    /**
     * The model for the navigation state.
     */
    NavigationModel: INavigationModel;

    /**
     * The model of globally accessable data.
     */
    GlobalDataModel: IGlobalDataModel;
}

/**
 * Model that represents the store.
 */
const StoreModel: IStoreModel = {
    NavigationModel,
    GlobalDataModel,
};

// generate typed hooks
const { useStoreActions, useStoreState, useStoreDispatch, useStore } = createTypedHooks<IStoreModel>();

// offer typed hooks for consumers
export { useStoreActions, useStoreState, useStoreDispatch, useStore };

/**
 * The store.
 */
export const Store = createStore(StoreModel);
