import { IButtonStyles, PrimaryButton } from '@fluentui/react';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import History from '../navigation/BrowserHistory';

const NotFoundViewContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const FlexWrapper = styled.div`
    display: flex;
    text-align: center;
`;

/**
 * View component that indicates a not found to the user.
 */
export const NotFoundView: FunctionComponent = () => {
    /**
     * Styles of the button that is used to return to the home view.
     */
    const returnHomeButtonStyles: IButtonStyles = {
        root: {
            marginTop: 25,
        },
    };

    return (
        <NotFoundViewContainer>
            <FlexWrapper>Ihre angefragte Seite konnte nicht gefunden werden!</FlexWrapper>
            <FlexWrapper>Kehren Sie zur Startseite zurück.</FlexWrapper>
            <PrimaryButton styles={returnHomeButtonStyles} text="Home" onClick={() => History.push('/')} />
        </NotFoundViewContainer>
    );
};
